import 'jquery-ui/ui/widgets/sortable'

export function multiSelect () {
  $('.basic-select2').select2({ theme: 'bootstrap' })
  $('.basic-select2-with-clear').select2({ allowClear: true })
  $('.basic-select2-with-other').select2({
    theme: 'bootstrap',
    tags: true,
    createTag: function (params) {
      return {
        id: params.term,
        text: params.term,
        newOption: true
      }
    },
    templateResult: function (data) {
      const $result = $('<span></span>')
      $result.text(data.text)
      if (data.newOption) {
        $result.append(' &nbsp;&nbsp;&nbsp;<em>(click to enter new)</em>')
      }
      return $result
    }
  })
}

export function sortableSelect () {
  $('.basic-select2-with-other').each(function () {
    const select = $(this)
    const ul = $(select).next('.select2-container').first('ul.select2-selection__rendered')
    ul.sortable({
      placeholder: 'ui-state-highlight',
      forcePlaceholderSize: true,
      items: 'li:not(.select2-search__field)',
      tolerance: 'pointer',
      stop: function () {
        $($(ul).find('.select2-selection__choice').get().reverse()).each(function () {
          /* keep old way of finding option and re-sorting */
          const id = $(this).data('data') && $(this).data('data').id
          let option = select.find('option[value="' + id + '"]')[0]

          /* add new way that matches titles as fallback */
          if (!id || !option) {
            const value = $(this).attr('title')
            option = select.find('option[value="' + value + '"]')[0]
          }

          $(select).prepend(option)
        })
      }
    })
  })
}

export function formatText (icon) {
  return $('<span><i class="fas fa-light fa-xl fa-' + $(icon.element).val() + '"></i>  <em>' + icon.text + '</em></span>')
}
