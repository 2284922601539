export function autofillProviderData ($clearanceTypeField) {
  $clearanceTypeField.change(function () {
    let clearanceType = this
    let clrIndex = $($('.clearance_type')).index(this)
    let providerNameClass = $('.provider_name_class').eq(clrIndex)[0]
    let providerPhoneClass = $('.provider_phone_class').eq(clrIndex)[0]
    let providerFaxClass = $('.provider_fax_class').eq(clrIndex)[0]
    let selClearanceType = clearanceType.options[clearanceType.selectedIndex].value
    if (selClearanceType === 'Medical') {
      const providerName = $('.clearance_type').data('providername')
      if (providerName && providerNameClass.value === '') {
        providerNameClass.setAttribute('value', providerName)
      }
      const providerPhone = $('.clearance_type').data('phonenumber')
      if (providerPhone && providerPhoneClass.value === '') {
        providerPhoneClass.setAttribute('value', providerPhone)
      }
      const providerFax = $('.clearance_type').data('faxnumber')
      if (providerFax && providerFaxClass.value === '') {
        providerFaxClass.setAttribute('value', providerFax)
      }
    }
  })
}
