$(function () {
  $('#announcements-bar').on('click', function () {
    $('#announcements-bar').hide('slide')
    $('#announcements-icon').show()
    $.post({url: '/announcements/' + $('#announcements-bar').data('announcement-id') + '/dismiss',
      type: 'POST',
      dataType: 'json'})
  })
  $('#announcements-icon').on('click', function () {
    $('#announcements-bar').show('slide')
    $('#announcements-icon').hide()
    $.post({url: '/announcements/' + $('#announcements-bar').data('announcement-id') + '/undismiss',
      type: 'POST',
      dataType: 'json'})
  })
})
