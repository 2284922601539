import * as selectOther from './select_other'

$(function () {
  selectOther.selectOther()

  // Prefill dropdown with other value
  $('.basic-select2-with-other').each(function () {
    const optionPrefill = $(this).attr('prefill')
    if (typeof optionPrefill !== 'undefined' && $("option[value='" + optionPrefill + "']", this).length < 1) {
      $(this).append('<option value="' +
        optionPrefill +
        '" selected="selected">' +
        optionPrefill +
        '</option>')
    }
  })

  // Prefill multi-select with other value
  $('.select_other.multi-other').each(function () {
    const prefillString = $(this).attr('prefill')
    if (typeof prefillString !== 'undefined') {
      let prefillOptions = JSON.parse(prefillString)
      for (let i = 0; i < prefillOptions.length; i++) {
        if ($("option[value='" + prefillOptions[i] + "']", this).length < 1) {
          $("option[value='']", this).remove()
          $(this).append('<option value="' +
            prefillOptions[i] +
            '" selected="selected">' +
            prefillOptions[i] +
            '</option>')
        }
      }
    }
  })
})
