export function reRenderDescription () {
  let $repetitions = $('#repetitions').val()
  let $daysInterval = $('#days_interval').val()
  let $shortDescription = $('#short_description_field').val()
  if ($repetitions === 0) {
    if ($daysInterval > 1) {
      $('#Natural_Language_Description').html('The ' + $shortDescription + ' task will prompt patients to complete the task every ' + $daysInterval.bold() + ' days.'.bold())
    } else {
      $('#Natural_Language_Description').html('The ' + $shortDescription + ' task will prompt patients to complete the task every ' + $daysInterval.bold() + ' day.'.bold())
    }
  } else if ($repetitions === 1) {
    $('#Natural_Language_Description').html('The ' + $shortDescription + ' task will prompt patients to complete the task ' + 'once.'.bold())
  } else if ($repetitions > 1 && $daysInterval === 1) {
    $('#Natural_Language_Description').html('The ' + $shortDescription + ' task will prompt patients to complete a task every day until they complete' + $repetitions.bold() + 'tasks.')
  } else if ($repetitions > 1 && $daysInterval > 1) {
    $('#Natural_Language_Description').html('The ' + $shortDescription + ' task will prompt patients to complete a task every ' + $daysInterval.bold() + ' days'.bold() + ' until they complete ' + $repetitions.bold() + ' tasks.')
  } else {
    $('#Natural_Language_Description').html('The ' + $shortDescription + ' task will prompt patients to complete a task until they complete all tasks.')
  }
}
