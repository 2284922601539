import { createConsumer } from '@rails/actioncable'

export default function unreadCableConnection () {
  let programId = $('div[id^="monitoring-dashboard"]')[0].id.replace(/\D/g, '')
  let consumer = createConsumer('/cable')
  consumer.subscriptions.create({channel: 'UnreadChannel', room: programId}, {
    received: function (data) {
      this.updateReadStatus(data)
    },

    updateReadStatus: function (data) {
      const messageIcon = document.querySelector(`.conv_${data.conversation.id}`)
      if (data.conversation.unread) {
        if (messageIcon.style.color === 'rgb(185, 74, 71)') { return }
        messageIcon.style.color = '#B94A47'
      } else {
        messageIcon.style.color = '#57aabb'
      }
    }
  })
}
