export function initTaskCalendar (taskCalendar) {
  if ($('#do_not_schedule').is(':checked')) {
    $('#task_scheduled_for').val('')
  }
  /* initialize the external events
  ----------------------------------------------------------------- */
  $('#tasks-to-schedule .fc-event').each(function () {
    // store data so the calendar knows to render an event upon drop
    $(this).data('event', {
      title: $.trim($(this).text()), // use the element's text as the event title
      stick: true // maintain when user navigates (see docs on the renderEvent method)
    })
    // make the event draggable using jQuery UI
    $(this).draggable({
      zIndex: 999,
      revert: true, // will cause the event to go back to its
      revertDuration: 0 //  original position after the drag
    })
  })
  /* initialize the calendar
  ----------------------------------------------------------------- */
  $('#calendar').fullCalendar({
    header: {
      left: 'month,agendaWeek,agendaDay',
      center: 'title',
      right: 'prev,next today'
    },
    defaultView: typeof (taskCalendar.defaultView) === 'undefined' ? 'month' : taskCalendar.defaultView,
    slotDuration: '00:15:00',
    snapDuration: '00:05:00',
    businessHours: {
      // days of week. an array of zero-based day of week integers (0=Sunday)
      dow: [ 1, 2, 3, 4, 5 ] // Monday - Thursday
    },
    weekends: false,
    minTime: '07:30',
    maxTime: '19:30',
    timezone: 'UTC',
    height: taskCalendar.height,
    editable: false,
    // droppable: true, // this allows things to be dropped onto the calendar
    // drop: function(date) {
    //   // is the "remove after drop" checkbox checked?
    //   if ($('#drop-remove').is(':checked')) {
    //     // if so, remove the element from the "Draggable Events" list
    //     $(this).remove()
    //   }
    //   // alert("Event start time: " + date.format())
    // },
    events: taskCalendar.events
  })
}
