import 'jquery-ui/ui/widgets/sortable'

let state = {}
export function deleteChoice (e) {
  e.preventDefault()
  const li = $(e.target).parent()
  let choiceId = li.data('choiceId')

  if (!confirm('Delete "' + $(li).text() + '"?')) { return }

  $.ajax(
    {
      url: '/choice_sets/' + state.choiceSetId + '/choices/' + choiceId,
      type: 'DELETE',
      success: function (data, textStatus, jqXHR) {
        li.slideUp(function () { li.remove() })
      },
      error: function (jqXHR, textStatus, errorThrown) {
        alert(jqXHR.responseJSON.error)
      }
    })
}

export function addChoice (e) {
  e.preventDefault()
  let input = $(e.target).parents('div.new-item').find('input[type=text]')
  if (!input.val().trim()) { return }
  let $ol = $(e.target).parents('section.choice-set-editor').find('> ol')

  $.ajax(
    {
      url: '/choice_sets/' + state.choiceSetId + '/choices',
      type: 'POST',
      data: {text: input.val()},
      success: function (data, textStatus, jqXHR) {
        addChoiceElement($ol, data)
        input.val('')
      },
      error: function (jqXHR, textStatus, errorThrown) {
        alert(jqXHR.responseJSON.error)
      }
    })
}
export function submitChoiceSetForm (e) {
  e.preventDefault()
  let formData = {}
  $.map($(this).find(':input[name]'), function (elem) {
    formData[$(elem).attr('name')] = $(elem).val()
  })

  $.ajax(
    {
      url: '/choice_sets/' + $(e.target).data('choice-set-id'),
      type: 'POST',
      data: formData,
      success: function (data, textStatus, jqXHR) {
        setState()
      },
      error: function (jqXHR, textStatus, errorThrown) {
        alert('unhandled exception.')
      }
    })
}
export function initChoices () {
  $.ajax({
    url: '/choice_sets/' + state.choiceSetId + '/choices',
    method: 'GET',
    success: function (data) {
      var $ol = $('section.choice-set-editor > ol')
      $.map(data.choices, function (choice) {
        addChoiceElement($ol, choice)
      })
    },
    error: function () {
      alert('unhandled exception')
    }
  })
}

export function setState () {
  let $form = $('form#editChoiceSet')
  state.choiceSetId = $form.data('choice-set-id')
  state.customSort = $form.find('select#customSort').val() === '1'
  initSortableChoices()
}

// helper functions
const addChoiceElement = function ($ol, choice) {
  let $newElement = $(
    '<li data-choice-id="' + choice.id + '" data-choice-seq="' + choice.sequence + '">' +
    '<i class="glyphicon glyphicon-option-vertical" rel="reorder"' +
    (state.customSort ? '' : ' style="width: 0px"') + '></i>' +
    '<div>' + choice.text + '</div>' +
    '<i class="fas fa-trash delete-choice" rel="tooltip" title="" data-original-title="Delete" ></i>' +
    '</li>'
  )
  if (state.customSort) {
    $ol.append($newElement)
  } else {
    let added = false
    $ol.find('li').each(function (idx, li) {
      let $li = $(li)
      if ($li.text().trim() > choice.text.trim()) {
        $newElement.insertBefore($li)
        added = true
        return false
      }
    })
    if (!added) {
      $ol.append($newElement)
    }
  }
  $ol.sortable('refresh')
}

const serializeChoice = function (elem) {
  let $elem = $(elem)
  return {
    id: $elem.data('choice-id'),
    text: $elem.text().trim(),
    sequence: $elem.data('choice-seq')
  }
}

const sortChoices = function (ev, elem) {
  let updatedChoices = []
  $('body#choice_sets-show section.choice-set-editor > ol > li').map(function (idx, elem) {
    var $choice = $(elem)
    if ($choice.data('choice-seq') !== idx) {
      updatedChoices.push($choice)
      $choice.data('choice-seq', idx)
    }
  })
  let data = $.map(updatedChoices, serializeChoice)
  if (updatedChoices.length > 0) {
    $.ajax(
      {
        url: '/choice_sets/' + state.choiceSetId + '/choices',
        type: 'PATCH',
        contentType: 'application/json',
        data: JSON.stringify({choices: data}),
        error: function (jqXHR, textStatus, errorThrown) {
          alert('unhandled exception.')
        }
      })
  }
}

const initSortableChoices = function (editor) {
  let $ol = $('body#choice_sets-show section.choice-set-editor > ol')
  $ol.sortable({
    update: sortChoices,
    axis: 'y'
  })
  let handles = $ol.find('i[rel=reorder]')
  let openWidth = $(handles[0]).css('font-size')

  if (state.customSort) {
    $ol.sortable('enable')
    handles.animate({width: openWidth})
    $('#orderTip').slideDown()
  } else {
    $ol.sortable('disable')
    handles.animate({width: 0})
    $('#orderTip').slideUp()
  }
}
