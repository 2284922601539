import { createConsumer } from '@rails/actioncable'

export default function measurementEventsCableConnection () {
  let programId = $('[id^="measurement-events-datatable"]')[0].id.replace(/\D/g, '')
  let consumer = createConsumer('/cable')
  consumer.subscriptions.create({
    channel: 'MeasurementEventsChannel',
    program_id: programId
  }, {
    received: function (data) {
      const oldCount = parseInt($('#tab-alert-count').text())
      const statusFilter = (
        new URLSearchParams(location.search).get('status') || 'active'
      ).toLowerCase()

      // if we go from not closed to closed, or else if we go from closed to not closed
      if (data.status_was !== 'closed' && data.status === 'closed') {
        $('#tab-alert-count').text(statusFilter === 'active' ? oldCount - 1 : oldCount + 1)
      } else if (data.status_was === 'closed' && data.status !== 'closed') {
        $('#tab-alert-count').text(statusFilter === 'active' ? oldCount + 1 : oldCount - 1)
      }

      $(`#${data.id} .measurement-event-status-and-date`).html(data.status_html)
      $(`#${data.id} .measurement-event-claimed-by`).html(data.claimed_by)
      $(`#${data.id} .measurement-event-actions`).html(data.actions)

      $('.data-remote-link-table tr td:last-child a[data-remote]').click(function (e) {
        $.rails.handleRemote($(this))
        e.preventDefault()
        e.stopImmediatePropagation() // prevent duplicated click handlers (datatable initComplete + fnDrawCallback) from triggering
      })
    }
  })
}
