export function mfaModalForm () {
  $('#mfa_form_submit').on('click', function () {
    let mfaCodeInput = $('#mfa_code_input').val()

    if (mfaCodeInput) {
      $('#mfa_code').val(mfaCodeInput) // MFA code from modal inserted into user form
      $('.simple_form.edit_user').submit()
      $('#mfa_code_modal').modal('hide')
      $('#mfa_code').val('') // MFA code form field cleared
      $('#mfa_code_input').val('') // MFA code modal field cleared
      $('#help_block').hide()
      $('.controls-container').removeClass('has-error')
    } else {
      $('.controls-container').addClass('has-error')
      $('#help_block').show()
    }
  })
}
