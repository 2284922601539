import * as prescriptions from './prescriptions'
import * as select2Setup from 'select2_setup/select2_setup'

$(function () {
  $('#new_prescriptions').on('cocoon:after-insert', function (e, insertedItem) {
    select2Setup.multiSelect()
    select2Setup.sortableSelect()
    prescriptions.changeMedicationList()
  })
})
