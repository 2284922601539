export function initAddressSameAsPatient ($carePartnerBlock) {
  const fields = ['address_1', 'address_2', 'city', 'state', 'zip']
  $carePartnerBlock.find('.same_as_patient')
    .prop('checked', checkAddressSame($carePartnerBlock, fields))
    .on('change', function () {
      for (let i = 0, len = fields.length; i < len; i++) {
        $(this).closest('.nested-fields')
          .find('.patient_care_partners_' + fields[i] + ' input')
          .val(this.checked ? $('input#patient_' + fields[i]).val() : '')
      }
    })
}

export function checkAddressSame ($carePartnerBlock, fields) {
  let same = true
  for (let i = 0, len = fields.length; i < len; i++) {
    if ($carePartnerBlock.find('.patient_care_partners_' + fields[i] + ' input').val() !== $('input#patient_' + fields[i]).val()) {
      same = false
    }
  }
  return same
}
