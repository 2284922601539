export function submitActiveStatus (e) {
  e.preventDefault()
  const monitoringEndDate = $('dd#monitoring_end_date').text()
  /* if the field is displayed then monitoring end reason has been
     configured in program fields */
  const hasMonitoringEndReasonConfigured = $('dd#monitoring_end_reason').length > 0
  const monitoringEndReason = $('dd#monitoring_end_reason').text()
  const isActive = $('#deactivate-button').attr('class').split(/\s+/).includes('active')
  const hasEmptyValues = (monitoringEndDate === '' ||
                          (hasMonitoringEndReasonConfigured && monitoringEndReason === ''))

  if (isActive && hasEmptyValues && $('#monitoring_end_date_modal').length) {
    $('#monitoring_end_date_modal').modal('show')
  } else {
    $('#activate-form').submit()
  }
}

export function setupTabRestore () {
  // Javascript to enable link to tab
  let hash = document.location.hash
  let prefix = 'tab_'
  if (hash) {
    $('.nav-tabs a[href=' + hash.replace('#', '\\#').replace(prefix, '') + ']').tab('show')
  }
  // Change hash for page-reload
  $('.nav-tabs a').on('shown.bs.tab', function (e) {
    window.location.hash = e.target.hash.replace('#', '#' + prefix)
  })
}

export function hideDisabledThresholds () {
  // Disable all existing selects
  $('.emtc-box .measurement_type.basic-select2').prop('disabled', true)
  // Disable all new selects until submit
  $('.emtc-box-new .measurement_type.basic-select2').prop('disabled', true)

  $('.measurement_type').change(function () {
    let selectedOption = $(this).find(':selected')
    let enableThresholds = selectedOption.attr('enable_thresholds')
    let enableBaselineThresholds = selectedOption.attr('enable_baseline_thresholds')
    let index = $('.measurement_type').index(this)
    let thresholdFields = $('.threshold_fields').eq(index)
    let baselineThresholdFields = $('.baseline_threshold_fields').eq(index)
    let frequencyField = $('.frequency_field').eq(index)
    let frequency = selectedOption.attr('frequency')
    frequencyField.find('.frequency').val(frequency)
    if (enableThresholds === 'true') {
      thresholdFields.show()
      let lowAlert = selectedOption.attr('low_alert_threshold')
      thresholdFields.find('.low_alert').val(lowAlert)
      let highAlert = selectedOption.attr('high_alert_threshold')
      thresholdFields.find('.high_alert').val(highAlert)
    } else {
      thresholdFields.hide()
      thresholdFields.find('input').val('')
    }
    if (enableBaselineThresholds === 'true') {
      baselineThresholdFields.show()
      let lowAlert = selectedOption.attr('low_baseline_change_threshold')
      baselineThresholdFields.find('.low_alert').val(lowAlert)
      let highAlert = selectedOption.attr('high_baseline_change_threshold')
      baselineThresholdFields.find('.high_alert').val(highAlert)
    } else {
      baselineThresholdFields.hide()
      baselineThresholdFields.find('input').val('')
    }
    let alertOnChanges = selectedOption.attr('alert_on_changes')
    let changeThresholds = $('.change-thresholds').eq(index)
    if (alertOnChanges === 'true') {
      changeThresholds.show()
    } else {
      changeThresholds.hide()
    }
  })
}

export function initChecklistSummaries () {
  $('input[name="filter_summaries"]').click(function () {
    const $summaries = $(this).closest('div.checklist-summaries-table').find('div.checklist-summaries')
    $.ajax({
      type: 'GET',
      dataType: 'html',
      url: '/checklist_summaries',
      data: $(this).closest('div.checklist-summary-controls').find(':input').serialize()
    }).done(function (response) {
      $summaries.html(response)
    })
  })
}

export function initReportDownloadLink () {
  $('a#download_csv_episode_report').click(function () {
    if ($(this).data('ujs:disabled')) {
      return false
    }
    $.rails.disableElement($('a#download_csv_episode_report'))
    const params = {'from_date': $(this).data('from-date'), 'to_date': $(this).data('to-date')}
    let endpoint = '/episodes/episodes_report.csv'
    endpoint += '?' + $.param(params)
    fetch(endpoint)
      .then(resp => {
        if (resp.status !== 200) {
          throw resp.status
        }
        const filename = resp.headers.get('content-disposition').split('filename=')[1].replace(/"/g, '')
        return resp.blob().then(blob => {
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = url
          a.download = filename
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
        })
      })
      .catch((err) => {
        if (err === 400) {
          alert('Maximum CSV range is four months. Please filter to a shorter date range.')
        } else {
          alert('There was an error downloading the Episode Report CSV.')
        }
      })
      .finally(() => $.rails.enableElement($('a#download_csv_episode_report')))
    return false
  })
}
