import * as select2Setup from './select2_setup'
window.select2Setup = select2Setup

$(function () {
  select2Setup.multiSelect()
  select2Setup.sortableSelect()

  $('.select2-icon').select2({
    templateSelection: select2Setup.formatText,
    templateResult: select2Setup.formatText
  })
})
