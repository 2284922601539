import * as measurementTypeComponents from './measurement_type_components.js'
$(function () {
  $('#measurement_type_component_name').on('input', function () {
    if ($(this).val().trim() === 'baby_weight_kg') {
      $('#display_weight_change_in_grams').show()
    } else {
      $('#display_weight_change_in_grams').hide()
      $('#measurement_type_component_display_weight_change_in_grams').prop('checked', false)
    }

    if ($(this).val().trim().includes('weight')) {
      $('#trend_bmi').show()
    } else {
      $('#trend_bmi').hide()
      $('#measurement_type_component_trend_bmi').prop('checked', false)
    }
  })

  $('#measurement_type_component_trend_bmi').change(function () {
    if (this.checked) {
      $('#height-for-bmi').show()
    } else {
      $('#height-for-bmi').hide()
      $('#measurement_type_component_height_program_field_id').val('')
    }
  })

  if ($('#measurement-components')[0]) {
    measurementTypeComponents.setNameAndUnits()
    $(document).on('cocoon:after-insert', '.change-thresholds', function () {
      measurementTypeComponents.setNameAndUnits()
    })
  }
  if ($('.measurement_type_components')[0]) {
    $('#measurement_type_component_readable_name').keyup(function () {
      $('.change-threshold-readable-name').text($(this).val())
    })
    $('#measurement_type_component_database_units').keyup(function () {
      $('.change-threshold-units').text($(this).val())
    })
  }
})
