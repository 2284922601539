$(function () {
  if ($('.nested-fields > .row').length === 0) {
    $('#new_follow_up_tasks > .links').show()
  }

  $('#new_follow_up_tasks').on('cocoon:after-insert', function () {
    $('.add_fields').hide()
  })

  $('#new_follow_up_tasks').on('cocoon:after-remove', function () {
    if ($('.nested-fields > .row:visible').length === 0) {
      $('#new_follow_up_tasks > .links').show()
    }
  })
})
