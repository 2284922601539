import cableConnection from './consumer'
import unreadCableConnection from './unread'
import measurementEventsConnection from './measurement_events'

$(function () {
  if ($('#messages-tab').length > 0) {
    cableConnection()
    $('.mark-as-read').on('click', function (e) {
      const unreadCount = parseFloat(document.querySelector('.unread_count').innerHTML) - 1
      $('.unread_count').replaceWith(`<span class="alert-count unread_count summary">${unreadCount}</span>`)
    })
  } else if ($('div[id^="monitoring-dashboard"]').length > 0) {
    unreadCableConnection()
  }

  if ($('table[id^="measurement-events-datatable"]').length > 0) {
    measurementEventsConnection()
  }
})
