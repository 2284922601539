export function toggleCarets () {
  $('.monitor-wrap .survey-header.survey').on('click', function () {
    let surveyIndex = $(this).data('survey_index')

    $(`#survey-caret-right${surveyIndex}`).toggle()
    $(`#survey-caret-down${surveyIndex}`).toggle()
  })

  $('.monitor-wrap .survey-header.response-set').on('click', function () {
    let surveyIndex = $(this).data('survey_index')
    let responseSetIndex = $(this).data('response_set_index')

    $(`#response-set-caret-right${surveyIndex}-${responseSetIndex}`).toggle()
    $(`#response-set-caret-down${surveyIndex}-${responseSetIndex}`).toggle()
  })
}

export function stopLinkPropagation () {
  $('.download-rs-link').on('click', function () {
    event.stopPropagation()
  })
}
