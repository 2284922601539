import * as forms from './forms.js'

$(function () {
  $('form.prompt-for-save *').change(function () {
    window.formmodified = 1
  })
  $('input[type="submit"]').click(function () {
    window.formmodified = 0
  })
  window.onbeforeunload = forms.confirmExit

  // expand details if there's an error in the form inside
  $('.has-error').parent('details').each(function (idx, el) {
    el.setAttribute('open', '')
  })

  // persist details expanded state across page refresh
  $('details').each(function (idx, el) {
    if (JSON.parse(localStorage.getItem(el.id))) {
      el.setAttribute('open', '')
    }
  })

  $('details > summary').click(function (evt) {
    const details = evt.target.closest('details')
    localStorage.setItem(details.id, !details.hasAttribute('open'))
  })
})
