import 'datatables.net'
import 'datatables.net-bs'
import 'datatables.net-buttons'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-colreorder'
import * as linkSetup from './link_setup'
window.linkSetup = linkSetup

export function initDatatable ($table) {
  let datatable = null

  const fromOption = function (opt, def) {
    const val = $table.data('opt')
    return (val === undefined ? def : val)
  }

  const stateSaveCallback = function (state, settings) {
    $.ajax({
      url: $table.data('settings-url'),
      type: 'PUT',
      async: false,
      data: {settings: JSON.stringify(settings)},
      success: function (data) {
      },
      error: function (jqXHR, status, error) {
        alert('Unhandled Exception')
      }
    })
  }

  const stateLoadCallback = function (state) {
    // Note: If this needs to happen asynchronously, we must upgrade to DataTables 1.10.13
    let settings = {}

    $.ajax({
      url: $table.data('settings-url'),
      type: 'GET',
      async: false,
      success: function (data) {
        settings = data.settings
      },
      error: function (jqXHR, status, error) {
        alert('Unhandled Exception')
      }
    })

    return settings
  }

  const resetState = function (ev, dt, node, config) {
    datatable.state.clear()
    window.location.reload()
  }

  const filterColumn = function (ev) {
    const $elem = $(ev.target)
    const timestamp = new Date()
    $elem.data('last-change', timestamp)
    setTimeout(function () {
      // If there's been another change since this setTimeout was called, do nothing.
      if ($elem.data('last-change') !== timestamp) { return }
      datatable.column($elem.data('column-name') + ':name').search($elem.val())
      datatable.draw()
    }, 300)
  }

  const clickableRow = function (ev) {
    if ($table.data('alternate-row-link')) {
      const alternateId = this.getElementsByClassName('alternate-id')[0].id
      window.location.href = $table.data('row-link').replace('[:id]', alternateId)
    } else {
      window.location.href = $table.data('row-link').replace('[:id]', this.id)
    }
  }

  const createdRow = function (row, data, dataIndex) {
    var $row = $(row)
    if ($row.attr('id')) { $row.addClass('clickable') }
  }

  const onProcessing = function (e, settings, processing) {
    if (processing) { return }
    $table.addClass('loading_complete')
    $('.dataTables_wrapper').addClass('loading_complete')
  }

  const onInit = function () {
    $table.data('initialized', true)
  }

  let dom = "<'row'<'col-sm-6'B>"
  if ($table[0].id.includes('monitoring-dashboard')) {
    dom += "<'row'<'col-sm-12 dataTables_top_pagination'pil>>"
  }
  if ($table.data('table-filter') !== undefined) {
    dom += "<'col-sm-6'f>"
  }
  dom += '>'
  dom += "<'row'<'col-sm-12'tr>>"
  dom += "<'row'<'col-sm-12'pil>>"

  const opts = {
    columnDefs: [],
    dom: dom,

    // pagination
    pageLength: fromOption('page-length', 10),
    pagingType: 'full_numbers',

    orderMulti: false,
    language: {
      search: 'Filter Results: ',
      paginate: {
        first: '<<',
        previous: '<',
        next: '>',
        last: '>>'
      }
    },
    buttons: [],
    colReorder: {
      realtime: true
    },
    createdRow: createdRow,
    initComplete: function () {
      $('.loader').addClass('disabled')
      handleHyperlinkColumn() // apply on table load as elements not always present on initial fnDrawCallback event
    },
    fnDrawCallback: function () {
      handleHyperlinkColumn() // apply when datatable is redrawn for pagination
    }
  }

  if ($table.data('column-visibility')) {
    opts.buttons.push({extend: 'colvis', text: 'Column Visibility'})
  }

  if ($table.data('settings-url') !== undefined) {
    $.extend(opts, {
      stateSave: true,
      stateSaveCallback: stateSaveCallback,
      stateLoadCallback: stateLoadCallback
    })
    opts.buttons.push({text: 'Reset', action: resetState})
  }

  if ($table.data('column-definitions') !== undefined) {
    opts.columns = JSON.parse(decodeURIComponent($table.data('column-definitions'))).map(col => {
      if (col.render_html) {
        return col
      } else {
        col.render = $.fn.dataTable.render.text()
        return col
      }
    })
  }

  if ($table.data('source')) {
    $.extend(opts, {
      ajax: {
        url: $table.data('source'),
        method: 'POST',
        headers: {
          'X-Datatable-Named-Fields': 'true'
        },
        beforeSend: (req, settings) => {
          /* sorting by last_measurement_timestamp yields non-deterministic results
           * because null sorting is inconsistent in mysql, resulting in some records
           * appearing missing, the solution is to inject a second sorting column in
           * when sorting by last_measurement_timestamp. */
          const formData = new URLSearchParams(settings['data'])
          const activityIndex = opts.columns.findIndex((col) => col.name === 'last_measurement_timestamp')
          const mrnIndex = opts.columns.findIndex((col) => col.name === 'medical_rec_num')
          const orderIndex = parseInt(formData.get('order[0][column]'))

          if (orderIndex === activityIndex && mrnIndex !== -1) {
            formData.set('order[1][column]', mrnIndex.toString())
            formData.set('order[1][dir]', 'desc')

            settings['data'] = formData.toString()
          }
        }
      },
      serverSide: true,
      processing: true
    })
  }

  let sortColumn = $table.data('default-sort-column')
  if (sortColumn !== undefined) {
    // opts['aaSorting'] = [[sortColumn + ':name', 'asc']]
    const sortColumnIndex = opts.columns.findIndex((x) => x['name'] === sortColumn)

    if (sortColumnIndex < 0) {
      console.warn('Default sorting failed: no column named "' + sortColumn + '".')
    }

    opts['order'] = [[sortColumnIndex, 'asc']]
  }

  if ($table.data('row-link')) {
    $table.on('click', 'tbody tr.clickable', clickableRow)
  }

  datatable = $table
    .on('input', '.filter-column', filterColumn)
    .on('processing.dt', onProcessing)
    .on('init.dt', onInit)
    .DataTable(opts)

  // load values into filter inputs that were set on the saved state
  $table.find('.filter-column').each(function (idx, elem) {
    const $elem = $(elem)
    const column = datatable.column($elem.data('columnName') + ':name')
    const state = datatable.state.loaded()
    if (state) {
      $elem.val(state.columns[column.index()].search.search)
    }
  })

  // reset width of table; makes it full-width by default
  datatable.table().node().style.width = ''

  return datatable
}

export function handleHyperlinkColumn () {
  linkSetup.handleRemoteSubmissionLink()

  // prevent click in td containing links from propagating to clickable row handler
  $('.dtable tr td > a').each(function () {
    $(this).parent().click(function (e) {
      e.stopPropagation()
    })
  })
}
