import * as dates from './dates.js'

$(function () {
  dates.refreshDatePicker()
  $('#org_leave_date_fields').on('cocoon:after-insert', function () {
    dates.refreshDatePicker()
  })
  dates.refreshDateTimePicker()
  $('#ind_leave_date_times').on('cocoon:after-insert', function () {
    dates.refreshDateTimePicker()
  })

  $('div.datetime-picker').each(function (i) {
    $(this).datetimepicker().on('change.dp', function () {
      const windowRes = window.formmodified = 1
      return windowRes
    })
  })

  $('.input-group-addon.now').each(function (i) {
    $(this).on('click', function () {
      $(this).parent().find('input').blur().val(dates.nowDateTimeString())
      $(this).parent().find('.bootstrap-datetimepicker-widget').remove()
    })
  })

  dates.formatDateNowPicker()

  $('.inline_date_time_picker').each(function (i) {
    let timeIncrement = null
    if (typeof $(this).attr('time_increment') !== 'undefined') {
      timeIncrement = $(this).attr('time_increment')
    } else {
      timeIncrement = 5
    }
    window['inlineDateTimePicker' + i] = $(this).datetimepicker({
      inline: true,
      sideBySide: true,
      format: 'M-D-Y h:mm A',
      stepping: timeIncrement
    })
  })
})
