import * as monitoringRecords from './monitoring_records'
window.addMeasurementType = monitoringRecords.addMeasurementType
window.removeMeasurementType = monitoringRecords.removeMeasurementType
window.refreshTheRemoveEventListener = monitoringRecords.refreshTheRemoveEventListener

$(function () {
  $('#ei-complete').on('click', function () {
    window.onbeforeunload = null
    $('.prompt-for-save').submit()
  })

  $('#ei-incomplete').on('click', function () {
    window.onbeforeunload = null
    $('#episode_monitoring_record_attributes_ipad_asset_number').prev().css({ color: '#64bc9b' })
  })
})
