import * as choiceSets from './choice_sets'
$(function () {
  if ($('body#choice_sets-show').length === 0) { return }
  choiceSets.setState()
  choiceSets.initChoices()

  let editor = $('body#choice_sets-show section.choice-set-editor')
  editor.on('click', 'i.delete-choice', choiceSets.deleteChoice)
  editor.find('.add-choice').on('click', choiceSets.addChoice)
  editor.find('.new-item input[type=text]').on('keypress', function (e) {
    if (e.which === 13) { choiceSets.addChoice(e) }
  })
  $('#editChoiceSet').on('submit', choiceSets.submitChoiceSetForm)
})
