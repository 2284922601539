import * as navbar from './navbar'

$(function () {
  // hide the breadcrumbs when the navbar mobile menu is visible, otherwise display them
  navbar.subscribeToNotifications()

  $('.navbar-toggle').click(function () {
    if (!$('.navbar-collapse').hasClass('in')) {
      navbar.hideBreadcrumbs()
    }
  })
  $('.navbar-collapse').on('hidden.bs.collapse', function () {
    navbar.showBreadrumbs()
  })
  $(window).resize(function () {
    if ($(window).width() < 768 && $('.navbar-collapse').hasClass('in')) {
      navbar.hideBreadcrumbs()
    } else {
      navbar.showBreadrumbs()
    }
  })

  $('.dropdown-notifications-toggle').on('click', (e) => {
    $('.dropdown-menu.notification-menu').show()
  })

  $(document).click((e) => {
    const isClickInside = $(e.target).closest('.dropdown-menu.notification-menu').length
    const isClickIcon = $(e.target).closest('.dropdown-notifications-toggle').length

    if (!isClickInside && !isClickIcon) {
      $('.dropdown-menu.notification-menu').hide()
    }
  })
})
