import * as select2Setup from 'select2_setup/select2_setup'

export function initEvents () {
  // loop through all events and display the correct reasons field
  let events = $(document).find('.nested-fields')
  events.each(function (index, event) {
    setSource($(this))
    displayReasonOptions($(this).find('.event-type'))
    displaySmokingCessation($(this).find('.source'))
  })
  // set onchange event for Event Type dropdown
  $('.event-type').on('select2:select select2:unselecting', function () {
    let eventSelect = $(this).closest('.nested-fields')
    eventSelect.find('.reason-select').find('option[value=""]').prop('selected', 'selected')
    eventSelect.find('.select-multi option').removeAttr('selected')
    eventSelect.find('.select-multi').find('option[value=""]').remove()
    select2Setup.multiSelect()
    displayReasonOptions($(this))
  })
  // set onchange event for Source dropdown
  $('.source').on('select2:select select2:unselecting', function () {
    displaySmokingCessation($(this))
  })
}

function setSource (event) {
  if (event.data('source')) {
    event.find('.source').val(event.data('source'))
  }
}

export function displaySmokingCessation (select) {
  let thisEvent = select.closest('.nested-fields')
  if (select.val() !== '90 Day Post-Op') {
    thisEvent.find('.smoking-cessation-form').slideUp()
  } else {
    thisEvent.find('.smoking-cessation-form').slideDown()
  }
}

export function displayReasonOptions (select) {
  let thisEvent = select.closest('.nested-fields')
  if (select.val() === 'Complication') {
    thisEvent.find('.reasons').hide()
    thisEvent.find('.complication-reason-options').show()
  } else if (select.val() === 'Transfer') {
    thisEvent.find('.reasons').hide()
    thisEvent.find('.transfer-reason-options').show()
  } else {
    thisEvent.find('.reasons').hide()
    thisEvent.find('.reason-options').show()
  }
}
