import * as postAcuteEvents from './post_acute_events.js'
import * as dates from 'dates/dates.js'
import * as select2Setup from 'select2_setup/select2_setup'

$(function () {
  postAcuteEvents.initEvents()
  $('#post_acute_events').on('cocoon:after-insert', function (e, insertedItem) {
    postAcuteEvents.initEvents()
    dates.refreshDatePicker()
    dates.formatDateNowPicker()
    select2Setup.multiSelect()
  })
})
