export function showDmeDevices () {
  let select = $('#surgery_durable_med_equipment')
  select.on('change', function () {
    if (select.val() === 'Yes') {
      $('#durable_med_equipment_devices_select').show()
    } else {
      $('#durable_med_equipment_devices_select').hide()
    }
  })
}

export function loadDmeDevicesSelect () {
  let selectedOption = $('#surgery_durable_med_equipment option:selected')
  if (selectedOption.length > 0 && selectedOption[0].label === 'Yes') {
    $('#durable_med_equipment_devices_select').show()
  }
}
