import { createConsumer } from '@rails/actioncable'

export default function cableConnection () {
  let conversation = $('.chat-history').data('conversation')
  $('#messages-tab').addClass('AC')
  let consumer = createConsumer('/cable')
  consumer.subscriptions.create({channel: 'MessagesChannel', room: conversation}, {
    received: function (data) {
      this.resetForm(data)
      this.renderMessage(data)
      this.scrollToNewMessage()
      this.markMessagesAsRead(data)
      this.checkForMarkingAsRead()
    },

    resetForm: function (data) {
      const messageBody = document.querySelector('.message_body')
      const unreadCount = parseFloat(document.querySelector('.unread_count').innerHTML) + 1
      messageBody.classList.remove('has-error')
      messageBody.getElementsByTagName('textarea')[0].removeAttribute('aria-label')
      const helpBlock = messageBody.getElementsByClassName('help-block')[0]
      if (helpBlock) {
        helpBlock.remove()
      }
      $('#new_message').trigger('reset')
      if (!data.html.includes('cobalt-message')) {
        $('.unread_count').replaceWith(`<span class="alert-count unread_count summary">${unreadCount}</span>`)
      } else {
        $('.unread_count').replaceWith(`<span class="alert-count unread_count summary">0</span>`)
      }
    },

    renderMessage: function (data) {
      $('.message-list').append(data.html)
    },

    scrollToNewMessage: function () {
      const messageBody = document.querySelector('.chat-history')
      messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight
    },

    markMessagesAsRead: function (data) {
      if (data.html.includes('cobalt-message')) {
        $('.mark-as-read').replaceWith('<i class="fas fa-check read" rel="tooltip" title="Read"></i>')
      }
    },

    checkForMarkingAsRead: function () {
      $('.mark-as-read').off().on('click', function (e) {
        const unreadCount = parseFloat(document.querySelector('.unread_count').innerHTML) - 1
        $('.unread_count').replaceWith(`<span class="alert-count unread_count summary">${unreadCount}</span>`)
      })
    }
  })
}
