$(function () {
  $('[rel=tooltip]').tooltip({
    placement: 'bottom',
    'delay': {
      show: 1000
    }
  })

  $('[rel=admin-tooltip]').tooltip({
    placement: 'top',
    'delay': {
      show: 250
    }
  })

  $('[rel=notes-tooltip]').tooltip({
    placement: 'right',
    'delay': {
      show: 100
    }
  })
})
