import { createConsumer } from '@rails/actioncable'

export function hideBreadcrumbs () {
  $('#breadcrumbs-bg1').hide()
  $('#breadcrumbs-bg2').hide()
  $('#breadcrumbs-wrap').hide()
}

export function showBreadrumbs () {
  $('#breadcrumbs-bg1').show()
  $('#breadcrumbs-bg2').show()
  $('#breadcrumbs-wrap').show()
}

export function subscribeToNotifications () {
  const programId = $('#dropdown-notifications').data('programId')

  if (programId) {
    const consumer = createConsumer('/cable')
    consumer.subscriptions.create({ channel: 'NotificationsChannel', room: programId }, {
      received: function (data) {
        $('#navbar-notification-count').show()
        let currentCount = parseInt($('#navbar-notification-count').html()) || 0
        $('#navbar-notification-count').html(currentCount + 1)
      }
    })
  }
}
