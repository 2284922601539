export function addMeasurementType (button, buttonMtcIds) {
  let $emtcBox = $('.emtc-box').first().clone().appendTo('#measurement-components').show()
  $emtcBox.find('h2').text($(button).text())
  $emtcBox.find('.nested-fields').remove()
  $emtcBox.find('hr').remove()
  let $emtcLinks = $('#emtc-links').appendTo($emtcBox)
  for (var i = 0; i < buttonMtcIds.length; i++) {
    $(button).hide()
    $emtcLinks.find('.add_fields').first().click()
    let $select = $('.select').last()
    $select.val(buttonMtcIds[i]).trigger('change')
    if (i !== 0) {
      $emtcBox.find('.nested-fields').last().before('<hr>')
    }
    let $selectedOption = $select.find(':selected')
    let $changeThresholds = $select.parents('.emtc-fields').find('.change-thresholds')
    $(JSON.parse($selectedOption.attr('change_thresholds'))).each(function () {
      $changeThresholds.find('.add_fields').click()
      $changeThresholds.find('.alert-if').last().find('select').val($(this).attr('alert_if'))
      $changeThresholds.find('.amount').last().find('input').val($(this).attr('amount'))
      $changeThresholds.find('.days').last().find('input').val($(this).attr('days'))
    })
  }
  if ($('.btn-measurement-type:visible').size() === 0) {
    $('.click-to-add').hide()
  }
}

export function removeMeasurementType (link) {
  let confirm = window.confirm('Are you sure you want to delete this expected measurement?')
  if (confirm) {
    let $emtcBox = $(link).closest('.emtc-box')
    $emtcBox.find('.remove-expected-measurement').find('.remove_fields').click()
    let mtName = $emtcBox.find('h2').text()
    $(".btn-measurement-type:contains('" + mtName + "')").show()
    $emtcBox.hide()
    $('.click-to-add').show()
  }
}

export function refreshTheRemoveEventListener () {
  // Remove handler from existing elements
  $('.remove-expected-measurement').off()

  // Re-add event handler for all matching elements
  $('.remove-expected-measurement').on('click', function () {
    let emtcBox = $(this).closest('.emtc-box')
    if (emtcBox.children('.nested-fields:visible').length === 1) {
      emtcBox.removeClass('emtc-box')
    }
  })
}
