import Timeouts from './timeouts'

$(function () {
  if (typeof window.noTimeout === 'undefined') {
    window.Timeouts = new Timeouts()
    $(document).keydown(function () {
      window.Timeouts.resetLastUpdate()
    })
    $(document).click(function () {
      window.Timeouts.resetLastUpdate()
    })
  }
})
