export function enableDownloadLinkAgain () {
  $('#download_csv_report').on('ajax:complete', function (data, status) {
    let fromDate = $(this).data('from-date')
    let toDate = $(this).data('to-date')
    let url = $(this).data('report-url')
    let csv = $(this).data('csv')
    let programFilter = $(this).data('program-filter')

    // Episodes/Patients CSV Report
    if (fromDate && toDate) {
      url += '?from_date=' + fromDate + '&to_date=' + toDate
    }

    // Patients/Wellness CSV Report
    if (csv && programFilter) {
      if (url.includes('&')) { // fromDate & toDate values are present
        url += '&csv=' + csv + '&program_filter=' + programFilter
      } else {
        url += '?csv=' + csv + '&program_filter=' + programFilter
      }
    }

    window.open(url)
    $.rails.enableElement($('a[data-disable-with]'))
  })
}
