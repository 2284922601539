import * as episodes from './episodes.js'
import * as reports from '../reports/reports.js'
$(function () {
  $(document).ready(function () {
    if ($('#measurement-components')[0]) {
      $('.measurement_type').each(function (index) {
        let enableThresholds = $(this).attr('enable_thresholds')
        let enableBaselineThresholds = $(this).attr('enable_baseline_thresholds')
        let thresholdFields = $('.threshold_fields').eq(index)
        let baselineThresholdFields = $('.baseline_threshold_fields').eq(index)
        if (enableThresholds === 'true') {
          thresholdFields.show()
        } else {
          thresholdFields.hide()
        }
        if (enableBaselineThresholds === 'true') {
          baselineThresholdFields.show()
        } else {
          baselineThresholdFields.hide()
        }
        let alertOnChanges = $(this).find(':selected').attr('alert_on_changes')
        let changeThresholds = $('.change-thresholds').eq(index)
        if (alertOnChanges === 'true') {
          changeThresholds.show()
        } else {
          changeThresholds.hide()
        }
      })
      episodes.hideDisabledThresholds()
      $('#measurement-components').bind('cocoon:after-insert', function (e, emtc) {
        // add emtc-box-new temporary css class fon new emtc to enable them
        emtc.addClass('emtc-box-new')
        episodes.hideDisabledThresholds()
        $('select.measurement_type').last().select2({ theme: 'bootstrap' })
      })
    }

    $('form').submit(function () {
      // Enable all new selects on submit
      $('.emtc-box-new .measurement_type.basic-select2').prop('disabled', false)
    })

    $('input#deactivate-button').hover(
      function () {
        let $this = $(this) // caching $(this)
        $this.data('initialText', $this.attr('value'))
        $this.attr('value', $this.data('hovertext'))
      },
      function () {
        let $this = $(this) // caching $(this)
        $this.attr('value', $this.data('initialText'))
      }
    )

    // handling monitoring end date logic
    $('input#deactivate-button').on('click', episodes.submitActiveStatus)
    $('#ei-complete').on('click', function () {
      $('#date-activate-form').submit()
    })

    episodes.setupTabRestore()
    if ($('#dashboard_modal').length) {
      $('#dashboard_modal').modal('show')
    }

    // hide and show biometrics on episode
    if ($('.biometrics-toggle .fa-plus')[0]) {
      $('.biometrics-toggle').click(function () {
        $('.biometrics-hidden').toggle()
        if ($('.biometrics-toggle span').hasClass('fa-plus')) {
          $('.biometrics-toggle span').removeClass('fa-plus').addClass('fa-minus')
        } else {
          $('.biometrics-toggle span').removeClass('fa-minus').addClass('fa-plus')
        }
      })
    }

    // hide and show care partner fields on monitoring record
    if ($('.care-partners-toggle')[0]) {
      $('.care-partners-toggle').click(function () {
        $('.care-partners-hidden').toggle()
        if ($('.care-partners-toggle i').hasClass('fa-angle-down')) {
          $('.care-partners-toggle i').removeClass('fa-angle-down').addClass('fa-angle-up')
        } else {
          $('.care-partners-toggle i').removeClass('fa-angle-up').addClass('fa-angle-down')
        }
      })
    }

    // hide and show depression scores on episode
    if ($('.depression-score-toggle')[0]) {
      $('.depression-score-toggle').click(function () {
        $('.depression-score-hidden').toggle()
      })
    }

    // hide and show depression form on depression screenings view
    if ($('.depression-form-toggle')[0]) {
      $('.depression-form-toggle').click(function () {
        let parent = $(this).closest('.depression-form-parent')[0]
        let hiddenForm = $(parent).find('.depression-form-hidden')
        $(hiddenForm).toggle()

        if ($($(this).find('span')).hasClass('fa-caret-right ')) {
          $($(this).find('span')).removeClass('fa-caret-right ').addClass('fa-caret-down')
        } else {
          $($(this).find('span')).removeClass('fa-caret-down').addClass('fa-caret-right ')
        }
      })
    }

    episodes.initChecklistSummaries()
    $('input[name="filter_summaries"]').click()

    if ($('.measurement-type-name')[0]) {
      $('#show-hide-thresholds').find('span').click(function () {
        if ($(this).text().match('Show')) {
          $('.components-and-thresholds').show()
          let $thisHTML = $(this).html()
          $(this).html($thisHTML.replace('Show', 'Hide'))
          $(this).find('i').toggleClass('fa-angle-down fa-angle-up')
        } else {
          $('.components-and-thresholds').hide()
          let $thisHTML = $(this).html()
          $(this).html($thisHTML.replace('Hide', 'Show'))
          $(this).find('i').toggleClass('fa-angle-up fa-angle-down')
        }
      })
    }

    reports.enableDownloadLinkAgain()
    episodes.initReportDownloadLink()
  })
})
