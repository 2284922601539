import * as patients from './patients'

$(function () {
  // Wellness patients only
  if ($('#patient_primary_care_provider')[0]) {
    // Auto populate the PCP TYPE field when PCP is chosen
    $('#patient_primary_care_provider').on('change', function () {
      const selected = $(this).find(':selected')
      if (selected.data('uva_prov_flag')) {
        $('.patient_pcp_type select option[value="UVA"]').prop('selected', true)
        $('.patient_pcp_type .select2-selection__rendered').attr('title', 'UVA').html('UVA')
      } else {
        $('.patient_pcp_type select option[value="Non-UVA"]').prop('selected', true)
        $('.patient_pcp_type .select2-selection__rendered').attr('title', 'Non-UVA').html('Non-UVA')
      }
      $('#patient_pcp_npi').val(selected.data('pcp_npi'))
      $('#patient_pcp_phone').val(selected.data('pcp_phone'))
      $('#patient_pcp_fax').val(selected.data('pcp_fax'))
      $('#patient_pcp_address_1').val(selected.data('pcp_address_1'))
      $('#patient_pcp_address_2').val(selected.data('pcp_address_2'))
      $('#patient_pcp_city').val(selected.data('pcp_city'))
      $('#patient_pcp_state').val(selected.data('pcp_state'))
      $('#patient_pcp_zip').val(selected.data('pcp_zip'))
    })
  }

  $('.care-partner').each(function () {
    patients.initAddressSameAsPatient($(this))
  })
  $('#care_partners').bind('cocoon:after-insert', function (e, insertedItem) {
    patients.initAddressSameAsPatient($(insertedItem))
  })
})
