import * as clearances from './clearances'
import * as dates from '../dates/dates.js'

$(function () {
  $('#new-clearances').on('cocoon:after-insert', function (e, insertedItem) {
    clearances.autofillProviderData($(insertedItem).find('.clearance_type'))
    dates.refreshDatePicker()
    dates.refreshDateTimePicker()
  })
})
