
const asterisk = 42
function pad (str, max) {
  str = str.toString()
  return str.length < max ? pad('0' + str, max) : str
}

export function nowDateTimeString () {
  let now = new Date()
  let y = now.getFullYear()
  let mon = pad(now.getMonth() + 1, 2)
  let d = pad(now.getDate(), 2)
  let h = pad(now.getHours(), 2)
  let min = pad(now.getMinutes(), 2)
  return mon + '-' + d + '-' + y + ' ' + h + ':' + min
}

export function nowDateString () {
  let now = new Date()
  let y = now.getFullYear()
  let mon = pad(now.getMonth() + 1, 2)
  let d = pad(now.getDate(), 2)
  return mon + '-' + d + '-' + y
}

Object.getPrototypeOf($('.date-picker')).size = function () {
  return this.length
}

export function refreshDatePicker () {
  $('.date-picker').each(function (i) {
    $(this).datetimepicker({
      format: 'MM-DD-YYYY'
    }).on('change.dp', function () {
      let windowRes = window.formmodified = 1
      return windowRes
    }).on('keypress', function (e) {
      if (e.keyCode === asterisk) {
        $(this).children('input').val(nowDateString())
        $(this).children('input').trigger('change')
        e.preventDefault()
      };
    })
  })
}

export function refreshDateTimePicker () {
  $('.datetime-picker').each(function (i) {
    $(this).datetimepicker({
      format: 'MM-DD-YYYY hh:mm A'
    }).on('change.dp', function () {
      let windowRes = window.formmodified = 1
      return windowRes
    }).on('keypress', function (e) {
      if (e.keyCode === asterisk) {
        $(this).children('input').val(nowDateString())
        $(this).children('input').trigger('change')
        e.preventDefault()
      };
    })
  })
}

export function formatDateNowPicker () {
  $('.input-group-addon.date.now').each(function (i) {
    $(this).on('click', function () {
      $(this).parent().find('input').blur().val(nowDateString())
      $(this).parent().find('.bootstrap-datetimepicker-widget').remove()
    })
  })
}
