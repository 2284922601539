import * as datatableSetup from './datatable_setup'

$(function () {
  // simple datatable
  $('.datatable.unlinked').each(function () {
    datatableSetup.initializeUnlinkedTable($(this))
    // $(this).show()
  })

  // simple datatables with clickable rows
  $('.datatable.linked').each(function () {
    datatableSetup.initializeClickableTable($(this))
    // $(this).show()
  })

  // simple datatables with clickable rows and CSV export
  $('.datatable.linked-csv').each(function () {
    datatableSetup.initializeClickableCSVTable($(this))
    // $(this).show()
  })

  // ajax datatables with clickable rows
  $('.datatable.ajaxed-and-linked').each(function () {
    datatableSetup.initializeAjaxClickableTable($(this))
    // $(this).show()
  })

  $('.datatable.searchable-columns').each(function () {
    if ($.fn.DataTable.isDataTable($(this))) {
      datatableSetup.searchableColumns($(this))
    }
  })
})
