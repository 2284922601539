$(function () {
  $('#check_all').on('click', function () {
    const cbxs = $('input[data="reviewed"]')
    cbxs.prop('checked', !cbxs.prop('checked'))
  })

  $('#rs-complete').on('click', function () {
    window.onbeforeunload = null
    $('#response_set_complete_task')[0].value = true
    $('.prompt-for-save').submit()
  })

  $('#rs-incomplete').on('click', function () {
    window.onbeforeunload = null
    $('.prompt-for-save').submit()
  })

  // enforce validation of float for number input
  $('.string-qn input[type="number"]').keypress(function (e) {
    if (e.which === 8) { return } // allow delete
    const validkeys = ['.', '-', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
    if (validkeys.indexOf(e.key) < 0) {
      e.preventDefault()
    }
  })
})
