import * as datatables from './datatables'
import * as buttons from './buttons'

$(function () {
  $('.dtable').each(function () {
    datatables.initDatatable($(this))
  }).show()

  buttons.positionForTabbedDashboard()

  $(window).resize(function () {
    buttons.positionForTabbedDashboard()
  })
})
