import * as taskTypesActions from './task_types_actions.js'
import * as taskDescriptionRenderer from './task_description_renderer.js'
window.reRenderDescription = taskDescriptionRenderer.reRenderDescription

$(function () {
  taskTypesActions.setStartDate()
  const taskWrapper = $('#task_type_wrapper')

  $('#task_frequency').change(function () {
    if ($(this).val() === 'Annually') {
      $('.days_of_week').hide()
    } else {
      $('.days_of_week').show()
    }
  })

  // hide and show inline date-time-picker
  if ($('#task_scheduled_for.inline_date_time_picker')[0]) {
    const scheduledForPicker = $('#task_scheduled_for.inline_date_time_picker')
    const isTaskEdit = scheduledForPicker.attr('is_task_edit')
    const prefillScheduledDate = scheduledForPicker.attr('prefill_scheduled_date')
    // hide date-time-picker if editing a task without a scheduled date
    if (isTaskEdit === 'true' && prefillScheduledDate == null) {
      $('.scheduled-for-picker').hide()
    } else if (isTaskEdit === 'true') { // Show date-time-picker if scheduled date exists
      $('.scheduled-for-picker').show()
      $('#task_scheduled_for').val(window.inlineDateTimePicker0.data('date'))
    }

    // if date-time-picker is hidden, then check "Do not schedule" checkbox
    if (!$('.scheduled-for-picker').is(':visible')) {
      $('#do_not_schedule').prop('checked', true)
    }

    // toggle date-time-picker, update hidden scheduled_for date field
    $('#do_not_schedule').change(function () {
      if ($('#do_not_schedule').is(':checked')) {
        $('.scheduled-for-picker').slideUp()
        $('#task_scheduled_for').val('')
      } else {
        $('.scheduled-for-picker').slideDown()
        $('#task_scheduled_for').val(window.inlineDateTimePicker0.data('date'))
      }
    })

    // for edit case
    if ($('#task_task_type_id:disabled').length === 1 && $.inArray($('#task_task_type_id option:selected').text(), taskWrapper.data('task_types_not_for_scheduling')) === -1) {
      taskTypesActions.displayCalendar()
    }

    $('#task_task_type_id').change(function () {
      $('#calendarwrap').html('')
      if ($.inArray($('#task_task_type_id option:selected').text(), taskWrapper.data('task_types_not_for_scheduling')) === -1) {
        $('#task_scheduled_for').val(window.inlineDateTimePicker0.data('date'))
        $.ajax({
          type: 'GET',
          url: '/change_calendar',
          data: {
            task_type: $('#task_task_type_id option:selected').text(),
            user: taskWrapper.data('user_id')
          }
        })
        taskTypesActions.displayCalendar()
      } else {
        $('.scheduled-for-picker').slideUp()
        $('#calendarwrap').hide()
        $('#task_scheduled_for').val('')
        $('#do_not_schedule').prop('checked', true)
      }
      if ($.inArray($('#task_task_type_id option:selected').text(), taskWrapper.data('task_pharmacy_task_types')) > -1) {
        window.inlineDateTimePicker0.data('DateTimePicker').stepping(taskWrapper.data('pharmacy_task_time_increment'))
      } else {
        window.inlineDateTimePicker0.data('DateTimePicker').stepping(taskWrapper.data('default_task_time_increment'))
      }
    })
  } else {
    $('#task_task_type_id').change(function () {
      if ($.inArray($('#task_task_type_id option:selected').text(), taskWrapper.data('task_types_not_for_scheduling')) === -1) {
        $('.task_scheduled_for').show()
        $('.recurring_task_options').show()
      } else {
        taskTypesActions.hideScheduleDate()
      }
    })
    if ($('#task_task_type_id:disabled').length === 1 && $.inArray($('#task_task_type_id option:selected').text(), taskWrapper.data('task_types_not_for_scheduling')) > -1) {
      taskTypesActions.hideScheduleDate()
    } else {
      $('.task_scheduled_for').show()
      $('.recurring_task_options').show()
    }
  }

  $('#days_interval').on('change', taskDescriptionRenderer.reRenderDescription)
  $('#repetitions').on('change', taskDescriptionRenderer.reRenderDescription)
  $('#short_description_field').on('keyup', taskDescriptionRenderer.reRenderDescription)
  $('#days_interval').on('keyup', taskDescriptionRenderer.reRenderDescription)
  $('#repetitions').on('keyup', taskDescriptionRenderer.reRenderDescription)
})
