/******************************************************************************
Name:    Highslide JS
Version: 4.1.8 (October 27 2009)
Config:  default +events +unobtrusive +imagemap +slideshow +positioning +transitions +viewport +thumbstrip +inline +ajax +iframe +flash
Author:  Torstein HÃƒÂ¸nsi
Support: http://highslide.com/support

Licence:
Highslide JS is licensed under a Creative Commons Attribution-NonCommercial 2.5
License (http://creativecommons.org/licenses/by-nc/2.5/).

You are free:
	* to copy, distribute, display, and perform the work
	* to make derivative works

Under the following conditions:
	* Attribution. You must attribute the work in the manner  specified by  the
	  author or licensor.
	* Noncommercial. You may not use this work for commercial purposes.

* For  any  reuse  or  distribution, you  must make clear to others the license
  terms of this work.
* Any  of  these  conditions  can  be  waived  if  you  get permission from the
  copyright holder.

Your fair use and other rights are in no way affected by the above.
******************************************************************************/
if (!window.hs) {
    window.hs = {
        lang: {
            cssDirection: "ltr",
            loadingText: "Loading...",
            loadingTitle: "Click to cancel",
            focusTitle: "Click to bring to front",
            fullExpandTitle: "Expand to actual size (f)",
            creditsText: "Powered by <i>Highslide JS</i>",
            creditsTitle: "Go to the Highslide JS homepage",
            previousText: "Previous",
            nextText: "Next",
            moveText: "Move",
            closeText: "Close",
            closeTitle: "Close (esc)",
            resizeTitle: "Resize",
            playText: "Play",
            playTitle: "Play slideshow (spacebar)",
            pauseText: "Pause",
            pauseTitle: "Pause slideshow (spacebar)",
            previousTitle: "Previous (arrow left)",
            nextTitle: "Next (arrow right)",
            moveTitle: "Move",
            fullExpandText: "1:1",
            number: "Image %1 of %2",
            restoreTitle: "Click to close image, click and drag to move. Use arrow keys for next and previous."
        },
        graphicsDir: "highslide/graphics/",
        expandCursor: null,
        restoreCursor: null,
        expandDuration: 250,
        restoreDuration: 250,
        marginLeft: 15,
        marginRight: 15,
        marginTop: 15,
        marginBottom: 15,
        zIndexCounter: 1001,
        loadingOpacity: .75,
        allowMultipleInstances: !0,
        numberOfImagesToPreload: 5,
        outlineWhileAnimating: 2,
        outlineStartOffset: 3,
        padToMinWidth: !1,
        fullExpandPosition: "bottom right",
        fullExpandOpacity: 1,
        showCredits: !0,
        creditsTarget: "_self",
        enableKeyListener: !0,
        openerTagNames: ["a", "area"],
        transitions: [],
        transitionDuration: 250,
        dimmingOpacity: 0,
        dimmingDuration: 50,
        allowWidthReduction: !1,
        allowHeightReduction: !0,
        preserveContent: !0,
        objectLoadTime: "before",
        cacheAjax: !0,
        anchor: "auto",
        align: "auto",
        targetX: null,
        targetY: null,
        dragByHeading: !0,
        minWidth: 200,
        minHeight: 200,
        allowSizeReduction: !0,
        outlineType: null,
        skin: {
            controls: '<div class="highslide-controls"><ul><li class="highslide-previous"><a href="#" title="{hs.lang.previousTitle}"><span>{hs.lang.previousText}</span></a></li><li class="highslide-play"><a href="#" title="{hs.lang.playTitle}"><span>{hs.lang.playText}</span></a></li><li class="highslide-pause"><a href="#" title="{hs.lang.pauseTitle}"><span>{hs.lang.pauseText}</span></a></li><li class="highslide-next"><a href="#" title="{hs.lang.nextTitle}"><span>{hs.lang.nextText}</span></a></li><li class="highslide-move"><a href="#" title="{hs.lang.moveTitle}"><span>{hs.lang.moveText}</span></a></li><li class="highslide-full-expand"><a href="#" title="{hs.lang.fullExpandTitle}"><span>{hs.lang.fullExpandText}</span></a></li><li class="highslide-close"><a title="{hs.lang.closeTitle}" ><span>{hs.lang.closeText}</span></a></li></ul></div>',
            contentWrapper: '<div class="highslide-header"><ul><li class="highslide-previous"><a href="#" title="{hs.lang.previousTitle}" onclick="return hs.previous(this)"><span>{hs.lang.previousText}</span></a></li><li class="highslide-next"><a href="#" title="{hs.lang.nextTitle}" onclick="return hs.next(this)"><span>{hs.lang.nextText}</span></a></li><li class="highslide-move"><a href="#" title="{hs.lang.moveTitle}" onclick="return false"><span>{hs.lang.moveText}</span></a></li><li class="highslide-close"><a title="{hs.lang.closeTitle}" onclick="return hs.close(this)"><span>{hs.lang.closeText}</span></a></li></ul></div><div class="highslide-body"></div><div class="highslide-footer"><div><span class="highslide-resize" title="{hs.lang.resizeTitle}"><span></span></span></div></div>'
        },
        preloadTheseImages: [],
        continuePreloading: !0,
        expanders: [],
        overrides: ["allowSizeReduction", "useBox", "anchor", "align", "targetX", "targetY", "outlineType", "outlineWhileAnimating", "captionId", "captionText", "captionEval", "captionOverlay", "headingId", "headingText", "headingEval", "headingOverlay", "creditsPosition", "dragByHeading", "autoplay", "numberPosition", "transitions", "dimmingOpacity", "width", "height", "contentId", "allowWidthReduction", "allowHeightReduction", "preserveContent", "maincontentId", "maincontentText", "maincontentEval", "objectType", "cacheAjax", "objectWidth", "objectHeight", "objectLoadTime", "swfOptions", "wrapperClassName", "minWidth", "minHeight", "maxWidth", "maxHeight", "pageOrigin", "slideshowGroup", "easing", "easingClose", "fadeInOut", "src"],
        overlays: [],
        idCounter: 0,
        oPos: {
            x: ["leftpanel", "left", "center", "right", "rightpanel"],
            y: ["above", "top", "middle", "bottom", "below"]
        },
        mouse: {},
        headingOverlay: {},
        captionOverlay: {},
        swfOptions: {
            flashvars: {},
            params: {},
            attributes: {}
        },
        timers: [],
        slideshows: [],
        pendingOutlines: {},
        sleeping: [],
        preloadTheseAjax: [],
        cacheBindings: [],
        cachedGets: {},
        clones: {},
        onReady: [],
        uaVersion: /Trident\/4\.0/.test(navigator.userAgent) ? 8 : parseFloat((navigator.userAgent.toLowerCase().match(/.+(?:rv|it|ra|ie)[\/: ]([\d.]+)/) || [0, "0"])[1]),
        ie: document.all && !window.opera,
        safari: /Safari/.test(navigator.userAgent),
        geckoMac: /Macintosh.+rv:1\.[0-8].+Gecko/.test(navigator.userAgent),
        $: function(e) {
            if (e) return document.getElementById(e)
        },
        push: function(e, t) {
            e[e.length] = t
        },
        createElement: function(e, t, s, i, h) {
            var n = document.createElement(e);
            return t && hs.extend(n, t), h && hs.setStyles(n, {
                padding: 0,
                border: "none",
                margin: 0
            }), s && hs.setStyles(n, s), i && i.appendChild(n), n
        },
        extend: function(e, t) {
            for (var s in t) e[s] = t[s];
            return e
        },
        setStyles: function(e, t) {
            for (var s in t) hs.ie && "opacity" == s ? t[s] > .99 ? e.style.removeAttribute("filter") : e.style.filter = "alpha(opacity=" + 100 * t[s] + ")" : e.style[s] = t[s]
        },
        animate: function(e, t, s) {
            var i, h, n;
            if ("object" != typeof s || null === s) {
                var o = arguments;
                s = {
                    duration: o[2],
                    easing: o[3],
                    complete: o[4]
                }
            }
            for (var a in "number" != typeof s.duration && (s.duration = 250), s.easing = Math[s.easing] || Math.easeInQuad, s.curAnim = hs.extend({}, t), t) {
                var r = new hs.fx(e, s, a);
                i = parseFloat(hs.css(e, a)) || 0, h = parseFloat(t[a]), n = "opacity" != a ? "px" : "", r.custom(i, h, n)
            }
        },
        css: function(e, t) {
            if (e.style[t]) return e.style[t];
            if (document.defaultView) return document.defaultView.getComputedStyle(e, null).getPropertyValue(t);
            "opacity" == t && (t = "filter");
            var s = e.currentStyle[t.replace(/\-(\w)/g, function(e, t) {
                return t.toUpperCase()
            })];
            return "filter" == t && (s = s.replace(/alpha\(opacity=([0-9]+)\)/, function(e, t) {
                return t / 100
            })), "" === s ? 1 : s
        },
        getPageSize: function() {
            var e = document,
                t = (window, e.compatMode && "BackCompat" != e.compatMode ? e.documentElement : e.body),
                s = hs.ie ? t.clientWidth : e.documentElement.clientWidth || self.innerWidth,
                i = hs.ie ? t.clientHeight : self.innerHeight;
            return hs.page = {
                width: s,
                height: i,
                scrollLeft: hs.ie ? t.scrollLeft : pageXOffset,
                scrollTop: hs.ie ? t.scrollTop : pageYOffset
            }, hs.page
        },
        getPosition: function(e) {
            if (/area/i.test(e.tagName))
                for (var t = document.getElementsByTagName("img"), s = 0; s < t.length; s++) {
                    var i = t[s].useMap;
                    if (i && i.replace(/^.*?#/, "") == e.parentNode.name) {
                        e = t[s];
                        break
                    }
                }
            for (var h = {
                    x: e.offsetLeft,
                    y: e.offsetTop
                }; e.offsetParent;) e = e.offsetParent, h.x += e.offsetLeft, h.y += e.offsetTop, e != document.body && e != document.documentElement && (h.x -= e.scrollLeft, h.y -= e.scrollTop);
            return h
        },
        expand: function(e, t, s, i) {
            if (e || (e = hs.createElement("a", null, {
                    display: "none"
                }, hs.container)), "function" == typeof e.getParams) return t;
            if ("html" == i) {
                for (var h = 0; h < hs.sleeping.length; h++)
                    if (hs.sleeping[h] && hs.sleeping[h].a == e) return hs.sleeping[h].awake(), hs.sleeping[h] = null, !1;
                hs.hasHtmlExpanders = !0
            }
            try {
                return new hs.Expander(e, t, s, i), !1
            } catch (e) {
                return !0
            }
        },
        htmlExpand: function(e, t, s) {
            return hs.expand(e, t, s, "html")
        },
        getSelfRendered: function() {
            return hs.createElement("div", {
                className: "highslide-html-content",
                innerHTML: hs.replaceLang(hs.skin.contentWrapper)
            })
        },
        getElementByClass: function(e, t, s) {
            for (var i = e.getElementsByTagName(t), h = 0; h < i.length; h++)
                if (new RegExp(s).test(i[h].className)) return i[h];
            return null
        },
        replaceLang: function(e) {
            var t, s = /{hs\.lang\.([^}]+)\}/g,
                i = (e = e.replace(/\s/g, " ")).match(s);
            if (i)
                for (var h = 0; h < i.length; h++) t = i[h].replace(s, "$1"), void 0 !== hs.lang[t] && (e = e.replace(i[h], hs.lang[t]));
            return e
        },
        setClickEvents: function() {
            for (var e = document.getElementsByTagName("a"), t = 0; t < e.length; t++) {
                var s = hs.isUnobtrusiveAnchor(e[t]);
                s && !e[t].hsHasSetClick && (function() {
                    var i = s;
                    hs.fireEvent(hs, "onSetClickEvent", {
                        element: e[t],
                        type: i
                    }) && (e[t].onclick = "image" == s ? function() {
                        return hs.expand(this)
                    } : function() {
                        return hs.htmlExpand(this, {
                            objectType: i
                        })
                    })
                }(), e[t].hsHasSetClick = !0)
            }
            hs.getAnchors()
        },
        isUnobtrusiveAnchor: function(e) {
            return "highslide" == e.rel ? "image" : "highslide-ajax" == e.rel ? "ajax" : "highslide-iframe" == e.rel ? "iframe" : "highslide-swf" == e.rel ? "swf" : void 0
        },
        getCacheBinding: function(e) {
            for (var t = 0; t < hs.cacheBindings.length; t++)
                if (hs.cacheBindings[t][0] == e) {
                    var s = hs.cacheBindings[t][1];
                    return hs.cacheBindings[t][1] = s.cloneNode(1), s
                }
            return null
        },
        preloadAjax: function(e) {
            for (var t = hs.getAnchors(), s = 0; s < t.htmls.length; s++) {
                var i = t.htmls[s];
                "ajax" == hs.getParam(i, "objectType") && hs.getParam(i, "cacheAjax") && hs.push(hs.preloadTheseAjax, i)
            }
            hs.preloadAjaxElement(0)
        },
        preloadAjaxElement: function(e) {
            if (hs.preloadTheseAjax[e]) {
                var t = hs.preloadTheseAjax[e],
                    s = hs.getNode(hs.getParam(t, "contentId"));
                s || (s = hs.getSelfRendered());
                var i = new hs.Ajax(t, s, 1);
                i.onError = function() {}, i.onLoad = function() {
                    hs.push(hs.cacheBindings, [t, s]), hs.preloadAjaxElement(e + 1)
                }, i.run()
            }
        },
        focusTopmost: function() {
            for (var e, t, s = 0, i = -1, h = hs.expanders, n = 0; n < h.length; n++)(e = h[n]) && (t = e.wrapper.style.zIndex) && t > s && (s = t, i = n); - 1 == i ? hs.focusKey = -1 : h[i].focus()
        },
        getParam: function(e, t) {
            e.getParams = e.onclick;
            var s = e.getParams ? e.getParams() : null;
            return e.getParams = null, s && void 0 !== s[t] ? s[t] : void 0 !== hs[t] ? hs[t] : null
        },
        getSrc: function(e) {
            return hs.getParam(e, "src") || e.href
        },
        getNode: function(e) {
            var t = hs.$(e),
                s = hs.clones[e];
            return t || s ? s ? s.cloneNode(!0) : ((s = t.cloneNode(!0)).id = "", hs.clones[e] = s, t) : null
        },
        discardElement: function(e) {
            e && hs.garbageBin.appendChild(e), hs.garbageBin.innerHTML = ""
        },
        dim: function(e) {
            hs.dimmer || (hs.dimmer = hs.createElement("div", {
                className: "highslide-dimming highslide-viewport-size",
                owner: "",
                onclick: function() {
                    hs.fireEvent(hs, "onDimmerClick") && hs.close()
                }
            }, {
                visibility: "visible",
                opacity: 0
            }, hs.container, !0)), hs.dimmer.style.display = "", hs.dimmer.owner += "|" + e.key, hs.geckoMac && hs.dimmingGeckoFix ? hs.setStyles(hs.dimmer, {
                background: "url(" + hs.graphicsDir + "geckodimmer.png)",
                opacity: 1
            }) : hs.animate(hs.dimmer, {
                opacity: e.dimmingOpacity
            }, hs.dimmingDuration)
        },
        undim: function(e) {
            hs.dimmer && (void 0 !== e && (hs.dimmer.owner = hs.dimmer.owner.replace("|" + e, "")), void 0 !== e && "" != hs.dimmer.owner || hs.upcoming && hs.getParam(hs.upcoming, "dimmingOpacity") || (hs.geckoMac && hs.dimmingGeckoFix ? hs.dimmer.style.display = "none" : hs.animate(hs.dimmer, {
                opacity: 0
            }, hs.dimmingDuration, null, function() {
                hs.dimmer.style.display = "none"
            })))
        },
        transit: function(e, t) {
            var s = t || hs.getExpander();
            if (t = s, hs.upcoming) return !1;
            hs.last = s, hs.removeEventListener(document, window.opera ? "keypress" : "keydown", hs.keyHandler);
            try {
                hs.upcoming = e, e.onclick()
            } catch (e) {
                hs.last = hs.upcoming = null
            }
            try {
                e && "crossfade" == t.transitions[1] || t.close()
            } catch (e) {}
            return !1
        },
        previousOrNext: function(e, t) {
            var s = hs.getExpander(e);
            return !!s && hs.transit(s.getAdjacentAnchor(t), s)
        },
        previous: function(e) {
            return hs.previousOrNext(e, -1)
        },
        next: function(e) {
            return hs.previousOrNext(e, 1)
        },
        keyHandler: function(e) {
            if (e || (e = window.event), e.target || (e.target = e.srcElement), void 0 !== e.target.form) return !0;
            if (!hs.fireEvent(hs, "onKeyDown", e)) return !0;
            var t = hs.getExpander(),
                s = null;
            switch (e.keyCode) {
                case 70:
                    return t && t.doFullExpand(), !0;
                case 32:
                    s = 2;
                    break;
                case 34:
                case 39:
                case 40:
                    s = 1;
                    break;
                case 8:
                case 33:
                case 37:
                case 38:
                    s = -1;
                    break;
                case 27:
                case 13:
                    s = 0
            }
            if (null !== s) {
                if (hs.removeEventListener(document, window.opera ? "keypress" : "keydown", hs.keyHandler), !hs.enableKeyListener) return !0;
                if (e.preventDefault ? e.preventDefault() : e.returnValue = !1, t) return 0 == s ? t.close() : 2 == s ? t.slideshow && t.slideshow.hitSpace() : (t.slideshow && t.slideshow.pause(), hs.previousOrNext(t.key, s)), !1
            }
            return !0
        },
        registerOverlay: function(e) {
            hs.push(hs.overlays, hs.extend(e, {
                hsId: "hsId" + hs.idCounter++
            }))
        },
        addSlideshow: function(e) {
            var t = e.slideshowGroup;
            if ("object" == typeof t)
                for (var s = 0; s < t.length; s++) {
                    var i = {};
                    for (var h in e) i[h] = e[h];
                    i.slideshowGroup = t[s], hs.push(hs.slideshows, i)
                } else hs.push(hs.slideshows, e)
        },
        getWrapperKey: function(e, t) {
            var s, i = /^highslide-wrapper-([0-9]+)$/;
            for (s = e; s.parentNode;) {
                if (void 0 !== s.hsKey) return s.hsKey;
                if (s.id && i.test(s.id)) return s.id.replace(i, "$1");
                s = s.parentNode
            }
            if (!t)
                for (s = e; s.parentNode;) {
                    if (s.tagName && hs.isHsAnchor(s))
                        for (var h = 0; h < hs.expanders.length; h++) {
                            var n = hs.expanders[h];
                            if (n && n.a == s) return h
                        }
                    s = s.parentNode
                }
            return null
        },
        getExpander: function(e, t) {
            return void 0 === e ? hs.expanders[hs.focusKey] || null : "number" == typeof e ? hs.expanders[e] || null : ("string" == typeof e && (e = hs.$(e)), hs.expanders[hs.getWrapperKey(e, t)] || null)
        },
        isHsAnchor: function(e) {
            return e.onclick && e.onclick.toString().replace(/\s/g, " ").match(/hs.(htmlE|e)xpand/)
        },
        reOrder: function() {
            for (var e = 0; e < hs.expanders.length; e++) hs.expanders[e] && hs.expanders[e].isExpanded && hs.focusTopmost()
        },
        fireEvent: function(e, t, s) {
            return !e || !e[t] || !1 !== e[t](e, s)
        },
        mouseClickHandler: function(e) {
            if (e || (e = window.event), e.button > 1) return !0;
            e.target || (e.target = e.srcElement);
            for (var t = e.target; t.parentNode && !/highslide-(image|move|html|resize)/.test(t.className);) t = t.parentNode;
            var s = hs.getExpander(t);
            if (s && (s.isClosing || !s.isExpanded)) return !0;
            if (s && "mousedown" == e.type) {
                if (e.target.form) return !0;
                var i = t.className.match(/highslide-(image|move|resize)/);
                if (i) return hs.dragArgs = {
                    exp: s,
                    type: i[1],
                    left: s.x.pos,
                    width: s.x.size,
                    top: s.y.pos,
                    height: s.y.size,
                    clickX: e.clientX,
                    clickY: e.clientY
                }, hs.addEventListener(document, "mousemove", hs.dragHandler), e.preventDefault && e.preventDefault(), /highslide-(image|html)-blur/.test(s.content.className) && (s.focus(), hs.hasFocused = !0), !1;
                /highslide-html/.test(t.className) && hs.focusKey != s.key && (s.focus(), s.doShowHide("hidden"))
            } else if ("mouseup" == e.type)
                if (hs.removeEventListener(document, "mousemove", hs.dragHandler), hs.dragArgs) {
                    hs.styleRestoreCursor && "image" == hs.dragArgs.type && (hs.dragArgs.exp.content.style.cursor = hs.styleRestoreCursor);
                    var h = hs.dragArgs.hasDragged;
                    h || hs.hasFocused || /(move|resize)/.test(hs.dragArgs.type) ? (h || !h && hs.hasHtmlExpanders) && hs.dragArgs.exp.doShowHide("hidden") : hs.fireEvent(s, "onImageClick") && s.close(), hs.dragArgs.exp.releaseMask && (hs.dragArgs.exp.releaseMask.style.display = "none"), h && hs.fireEvent(hs.dragArgs.exp, "onDrop", hs.dragArgs), hs.hasFocused = !1, hs.dragArgs = null
                } else /highslide-image-blur/.test(t.className) && (t.style.cursor = hs.styleRestoreCursor);
            return !1
        },
        dragHandler: function(e) {
            if (!hs.dragArgs) return !0;
            e || (e = window.event);
            var t = hs.dragArgs,
                s = t.exp;
            s.iframe && (s.releaseMask || (s.releaseMask = hs.createElement("div", null, {
                position: "absolute",
                width: s.x.size + "px",
                height: s.y.size + "px",
                left: s.x.cb + "px",
                top: s.y.cb + "px",
                zIndex: 4,
                background: hs.ie ? "white" : "none",
                opacity: .01
            }, s.wrapper, !0)), "none" == s.releaseMask.style.display && (s.releaseMask.style.display = "")), t.dX = e.clientX - t.clickX, t.dY = e.clientY - t.clickY;
            var i = Math.sqrt(Math.pow(t.dX, 2) + Math.pow(t.dY, 2));
            if (t.hasDragged || (t.hasDragged = "image" != t.type && i > 0 || i > (hs.dragSensitivity || 5)), t.hasDragged && e.clientX > 5 && e.clientY > 5) {
                if (!hs.fireEvent(s, "onDrag", t)) return !1;
                "resize" == t.type ? s.resize(t) : (s.moveTo(t.left + t.dX, t.top + t.dY), "image" == t.type && (s.content.style.cursor = "move"))
            }
            return !1
        },
        wrapperMouseHandler: function(e) {
            try {
                e || (e = window.event);
                var t = /mouseover/i.test(e.type);
                e.target || (e.target = e.srcElement), hs.ie && (e.relatedTarget = t ? e.fromElement : e.toElement);
                var s = hs.getExpander(e.target);
                if (!s.isExpanded) return;
                if (!s || !e.relatedTarget || hs.getExpander(e.relatedTarget, !0) == s || hs.dragArgs) return;
                hs.fireEvent(s, t ? "onMouseOver" : "onMouseOut", e);
                for (var i = 0; i < s.overlays.length; i++) void 0, (h = hs.$("hsId" + s.overlays[i])) && h.hideOnMouseOut && (t && hs.setStyles(h, {
                    visibility: "visible",
                    display: ""
                }), hs.animate(h, {
                    opacity: t ? h.opacity : 0
                }, h.dur))
            } catch (e) {}
            var h
        },
        addEventListener: function(e, t, s) {
            e == document && "ready" == t && hs.push(hs.onReady, s);
            try {
                e.addEventListener(t, s, !1)
            } catch (i) {
                try {
                    e.detachEvent("on" + t, s), e.attachEvent("on" + t, s)
                } catch (i) {
                    e["on" + t] = s
                }
            }
        },
        removeEventListener: function(e, t, s) {
            try {
                e.removeEventListener(t, s, !1)
            } catch (i) {
                try {
                    e.detachEvent("on" + t, s)
                } catch (s) {
                    e["on" + t] = null
                }
            }
        },
        preloadFullImage: function(e) {
            if (hs.continuePreloading && hs.preloadTheseImages[e] && "undefined" != hs.preloadTheseImages[e]) {
                var t = document.createElement("img");
                t.onload = function() {
                    t = null, hs.preloadFullImage(e + 1)
                }, t.src = hs.preloadTheseImages[e]
            }
        },
        preloadImages: function(e) {
            e && "object" != typeof e && (hs.numberOfImagesToPreload = e);
            for (var t = hs.getAnchors(), s = 0; s < t.images.length && s < hs.numberOfImagesToPreload; s++) hs.push(hs.preloadTheseImages, hs.getSrc(t.images[s]));
            hs.outlineType ? new hs.Outline(hs.outlineType, function() {
                hs.preloadFullImage(0)
            }) : hs.preloadFullImage(0), hs.restoreCursor && hs.createElement("img", {
                src: hs.graphicsDir + hs.restoreCursor
            })
        },
        init: function() {
            if (!hs.container) {
                for (var e in hs.getPageSize(), hs.ieLt7 = hs.ie && hs.uaVersion < 7, hs.ie6SSL = hs.ieLt7 && "https:" == location.protocol, hs.langDefaults) void 0 !== hs[e] ? hs.lang[e] = hs[e] : void 0 === hs.lang[e] && void 0 !== hs.langDefaults[e] && (hs.lang[e] = hs.langDefaults[e]);
                hs.container = hs.createElement("div", {
                    className: "highslide-container"
                }, {
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: "100%",
                    zIndex: hs.zIndexCounter,
                    direction: "ltr"
                }, document.body, !0), hs.loading = hs.createElement("a", {
                    className: "highslide-loading",
                    title: hs.lang.loadingTitle,
                    innerHTML: hs.lang.loadingText,
                    href: "javascript:;"
                }, {
                    position: "absolute",
                    top: "-9999px",
                    opacity: hs.loadingOpacity,
                    zIndex: 1
                }, hs.container), hs.garbageBin = hs.createElement("div", null, {
                    display: "none"
                }, hs.container), hs.viewport = hs.createElement("div", {
                    className: "highslide-viewport highslide-viewport-size"
                }, {
                    visibility: hs.safari && hs.uaVersion < 525 ? "visible" : "hidden"
                }, hs.container, 1), hs.clearing = hs.createElement("div", null, {
                    clear: "both",
                    paddingTop: "1px"
                }, null, !0), Math.linearTween = function(e, t, s, i) {
                    return s * e / i + t
                }, Math.easeInQuad = function(e, t, s, i) {
                    return s * (e /= i) * e + t
                }, Math.easeOutQuad = function(e, t, s, i) {
                    return -s * (e /= i) * (e - 2) + t
                }, hs.hideSelects = hs.ieLt7, hs.hideIframes = window.opera && hs.uaVersion < 9 || "KDE" == navigator.vendor || hs.ie && hs.uaVersion < 5.5, hs.fireEvent(this, "onActivate")
            }
        },
        ready: function() {
            if (!hs.isReady) {
                hs.isReady = !0;
                for (var e = 0; e < hs.onReady.length; e++) hs.onReady[e]()
            }
        },
        updateAnchors: function() {
            for (var e, t, s, i = [], h = [], n = [], o = {}, a = 0; a < hs.openerTagNames.length; a++) {
                t = document.getElementsByTagName(hs.openerTagNames[a]);
                for (var r = 0; r < t.length; r++)
                    if (e = t[r], s = hs.isHsAnchor(e)) {
                        hs.push(i, e), "hs.expand" == s[0] ? hs.push(h, e) : "hs.htmlExpand" == s[0] && hs.push(n, e);
                        var l = hs.getParam(e, "slideshowGroup") || "none";
                        o[l] || (o[l] = []), hs.push(o[l], e)
                    }
            }
            return hs.anchors = {
                all: i,
                groups: o,
                images: h,
                htmls: n
            }, hs.anchors
        },
        getAnchors: function() {
            return hs.anchors || hs.updateAnchors()
        },
        close: function(e) {
            var t = hs.getExpander(e);
            return t && t.close(), !1
        }
    }, hs.fx = function(e, t, s) {
        this.options = t, this.elem = e, this.prop = s, t.orig || (t.orig = {})
    }, hs.fx.prototype = {
        update: function() {
            (hs.fx.step[this.prop] || hs.fx.step._default)(this), this.options.step && this.options.step.call(this.elem, this.now, this)
        },
        custom: function(e, t, s) {
            this.startTime = (new Date).getTime(), this.start = e, this.end = t, this.unit = s, this.now = this.start, this.pos = this.state = 0;
            var i = this;

            function h(e) {
                return i.step(e)
            }
            h.elem = this.elem, h() && 1 == hs.timers.push(h) && (hs.timerId = setInterval(function() {
                for (var e = hs.timers, t = 0; t < e.length; t++) e[t]() || e.splice(t--, 1);
                e.length || clearInterval(hs.timerId)
            }, 13))
        },
        step: function(e) {
            var t = (new Date).getTime();
            if (e || t >= this.options.duration + this.startTime) {
                this.now = this.end, this.pos = this.state = 1, this.update(), this.options.curAnim[this.prop] = !0;
                var s = !0;
                for (var i in this.options.curAnim) !0 !== this.options.curAnim[i] && (s = !1);
                return s && this.options.complete && this.options.complete.call(this.elem), !1
            }
            var h = t - this.startTime;
            return this.state = h / this.options.duration, this.pos = this.options.easing(h, 0, 1, this.options.duration), this.now = this.start + (this.end - this.start) * this.pos, this.update(), !0
        }
    }, hs.extend(hs.fx, {
        step: {
            opacity: function(e) {
                hs.setStyles(e.elem, {
                    opacity: e.now
                })
            },
            _default: function(e) {
                try {
                    e.elem.style && null != e.elem.style[e.prop] ? e.elem.style[e.prop] = e.now + e.unit : e.elem[e.prop] = e.now
                } catch (e) {}
            }
        }
    }), hs.Outline = function(e, t) {
        this.onLoad = t, this.outlineType = e;
        var s, i = hs.uaVersion;
        if (this.hasAlphaImageLoader = hs.ie && i >= 5.5 && i < 7, e) {
            hs.init(), this.table = hs.createElement("table", {
                cellSpacing: 0
            }, {
                visibility: "hidden",
                position: "absolute",
                borderCollapse: "collapse",
                width: 0
            }, hs.container, !0);
            var h = hs.createElement("tbody", null, null, this.table, 1);
            this.td = [];
            for (var n = 0; n <= 8; n++) {
                n % 3 == 0 && (s = hs.createElement("tr", null, {
                    height: "auto"
                }, h, !0)), this.td[n] = hs.createElement("td", null, null, s, !0);
                var o = 4 != n ? {
                    lineHeight: 0,
                    fontSize: 0
                } : {
                    position: "relative"
                };
                hs.setStyles(this.td[n], o)
            }
            this.td[4].className = e + " highslide-outline", this.preloadGraphic()
        } else t && t()
    }, hs.Outline.prototype = {
        preloadGraphic: function() {
            var e = hs.graphicsDir + (hs.outlinesDir || "outlines/") + this.outlineType + ".png",
                t = hs.safari ? hs.container : null;
            this.graphic = hs.createElement("img", null, {
                position: "absolute",
                top: "-9999px"
            }, t, !0);
            var s = this;
            this.graphic.onload = function() {
                s.onGraphicLoad()
            }, this.graphic.src = e
        },
        onGraphicLoad: function() {
            for (var e = this.offset = this.graphic.width / 4, t = [
                    [0, 0],
                    [0, -4],
                    [-2, 0],
                    [0, -8], 0, [-2, -8],
                    [0, -2],
                    [0, -6],
                    [-2, -2]
                ], s = {
                    height: 2 * e + "px",
                    width: 2 * e + "px"
                }, i = 0; i <= 8; i++)
                if (t[i]) {
                    if (this.hasAlphaImageLoader) {
                        var h = 1 == i || 7 == i ? "100%" : this.graphic.width + "px",
                            n = hs.createElement("div", null, {
                                width: "100%",
                                height: "100%",
                                position: "relative",
                                overflow: "hidden"
                            }, this.td[i], !0);
                        hs.createElement("div", null, {
                            filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod=scale, src='" + this.graphic.src + "')",
                            position: "absolute",
                            width: h,
                            height: this.graphic.height + "px",
                            left: t[i][0] * e + "px",
                            top: t[i][1] * e + "px"
                        }, n, !0)
                    } else hs.setStyles(this.td[i], {
                        background: "url(" + this.graphic.src + ") " + t[i][0] * e + "px " + t[i][1] * e + "px"
                    });
                    !window.opera || 3 != i && 5 != i || hs.createElement("div", null, s, this.td[i], !0), hs.setStyles(this.td[i], s)
                }
            this.graphic = null, hs.pendingOutlines[this.outlineType] && hs.pendingOutlines[this.outlineType].destroy(), hs.pendingOutlines[this.outlineType] = this, this.onLoad && this.onLoad()
        },
        setPosition: function(e, t, s, i, h) {
            var n = this.exp;
            n.wrapper.style, t = t || 0, e = e || {
                x: n.x.pos + t,
                y: n.y.pos + t,
                w: n.x.get("wsize") - 2 * t,
                h: n.y.get("wsize") - 2 * t
            }, s && (this.table.style.visibility = e.h >= 4 * this.offset ? "visible" : "hidden"), hs.setStyles(this.table, {
                left: e.x - this.offset + "px",
                top: e.y - this.offset + "px",
                width: e.w + 2 * this.offset + "px"
            }), e.w -= 2 * this.offset, e.h -= 2 * this.offset, hs.setStyles(this.td[4], {
                width: e.w >= 0 ? e.w + "px" : 0,
                height: e.h >= 0 ? e.h + "px" : 0
            }), this.hasAlphaImageLoader && (this.td[3].style.height = this.td[5].style.height = this.td[4].style.height)
        },
        destroy: function(e) {
            e ? this.table.style.visibility = "hidden" : hs.discardElement(this.table)
        }
    }, hs.Dimension = function(e, t) {
        this.exp = e, this.dim = t, this.ucwh = "x" == t ? "Width" : "Height", this.wh = this.ucwh.toLowerCase(), this.uclt = "x" == t ? "Left" : "Top", this.lt = this.uclt.toLowerCase(), this.ucrb = "x" == t ? "Right" : "Bottom", this.rb = this.ucrb.toLowerCase(), this.p1 = this.p2 = 0
    }, hs.Dimension.prototype = {
        get: function(e) {
            switch (e) {
                case "loadingPos":
                    return this.tpos + this.tb + (this.t - hs.loading["offset" + this.ucwh]) / 2;
                case "loadingPosXfade":
                    return this.pos + this.cb + this.p1 + (this.size - hs.loading["offset" + this.ucwh]) / 2;
                case "wsize":
                    return this.size + 2 * this.cb + this.p1 + this.p2;
                case "fitsize":
                    return this.clientSize - this.marginMin - this.marginMax;
                case "maxsize":
                    return this.get("fitsize") - 2 * this.cb - this.p1 - this.p2;
                case "opos":
                    return this.pos - (this.exp.outline ? this.exp.outline.offset : 0);
                case "osize":
                    return this.get("wsize") + (this.exp.outline ? 2 * this.exp.outline.offset : 0);
                case "imgPad":
                    return this.imgSize ? Math.round((this.size - this.imgSize) / 2) : 0
            }
        },
        calcBorders: function() {
            this.cb = (this.exp.content["offset" + this.ucwh] - this.t) / 2, this.marginMax = hs["margin" + this.ucrb]
        },
        calcThumb: function() {
            this.t = this.exp.el[this.wh] ? parseInt(this.exp.el[this.wh]) : this.exp.el["offset" + this.ucwh], this.tpos = this.exp.tpos[this.dim], this.tb = (this.exp.el["offset" + this.ucwh] - this.t) / 2, 0 != this.tpos && -1 != this.tpos || (this.tpos = hs.page[this.wh] / 2 + hs.page["scroll" + this.uclt])
        },
        calcExpanded: function() {
            var e = this.exp;
            this.justify = "auto", "center" == e.align ? this.justify = "center" : new RegExp(this.lt).test(e.anchor) ? this.justify = null : new RegExp(this.rb).test(e.anchor) && (this.justify = "max"), this.pos = this.tpos - this.cb + this.tb, this.maxHeight && "x" == this.dim && (e.maxWidth = Math.min(e.maxWidth || this.full, e.maxHeight * this.full / e.y.full)), this.size = Math.min(this.full, e["max" + this.ucwh] || this.full), this.minSize = e.allowSizeReduction ? Math.min(e["min" + this.ucwh], this.full) : this.full, e.isImage && e.useBox && (this.size = e[this.wh], this.imgSize = this.full), "x" == this.dim && hs.padToMinWidth && (this.minSize = e.minWidth), this.target = e["target" + this.dim.toUpperCase()], this.marginMin = hs["margin" + this.uclt], this.scroll = hs.page["scroll" + this.uclt], this.clientSize = hs.page[this.wh]
        },
        setSize: function(e) {
            var t = this.exp;
            if (t.isImage && (t.useBox || hs.padToMinWidth) ? (this.imgSize = e, this.size = Math.max(this.size, this.imgSize), t.content.style[this.lt] = this.get("imgPad") + "px") : this.size = e, t.content.style[this.wh] = e + "px", t.wrapper.style[this.wh] = this.get("wsize") + "px", t.outline && t.outline.setPosition(), t.releaseMask && (t.releaseMask.style[this.wh] = e + "px"), "y" == this.dim && t.iDoc && "auto" != t.body.style.height) try {
                t.iDoc.body.style.overflow = "auto"
            } catch (e) {}
            if (t.isHtml) {
                var s = t.scrollerDiv;
                void 0 === this.sizeDiff && (this.sizeDiff = t.innerContent["offset" + this.ucwh] - s["offset" + this.ucwh]), s.style[this.wh] = this.size - this.sizeDiff + "px", "x" == this.dim && (t.mediumContent.style.width = "auto"), t.body && (t.body.style[this.wh] = "auto")
            }
            "x" == this.dim && t.overlayBox && t.sizeOverlayBox(!0), "x" == this.dim && t.slideshow && t.isImage && (e == this.full ? t.slideshow.disable("full-expand") : t.slideshow.enable("full-expand"))
        },
        setPos: function(e) {
            this.pos = e, this.exp.wrapper.style[this.lt] = e + "px", this.exp.outline && this.exp.outline.setPosition()
        }
    }, hs.Expander = function(e, t, s, i) {
        if (!document.readyState || !hs.ie || hs.isReady) {
            this.a = e, this.custom = s, this.contentType = i || "image", this.isHtml = "html" == i, this.isImage = !this.isHtml, hs.continuePreloading = !1, this.overlays = [], this.last = hs.last, hs.last = null, hs.init();
            for (var h = this.key = hs.expanders.length, n = 0; n < hs.overrides.length; n++) {
                var o = hs.overrides[n];
                this[o] = t && void 0 !== t[o] ? t[o] : hs[o]
            }
            this.src || (this.src = e.href);
            var a = t && t.thumbnailId ? hs.$(t.thumbnailId) : e;
            if (a = this.thumb = a.getElementsByTagName("img")[0] || a, this.thumbsUserSetId = a.id || e.id, !hs.fireEvent(this, "onInit")) return !0;
            for (n = 0; n < hs.expanders.length; n++)
                if (hs.expanders[n] && hs.expanders[n].a == e && (!this.last || "crossfade" != this.transitions[1])) return hs.expanders[n].focus(), !1;
            if (!hs.allowSimultaneousLoading)
                for (n = 0; n < hs.expanders.length; n++) hs.expanders[n] && hs.expanders[n].thumb != a && !hs.expanders[n].onLoadStarted && hs.expanders[n].cancelLoading();
            if (hs.expanders[h] = this, hs.allowMultipleInstances || hs.upcoming || (hs.expanders[h - 1] && hs.expanders[h - 1].close(), void 0 !== hs.focusKey && hs.expanders[hs.focusKey] && hs.expanders[hs.focusKey].close()), this.el = a, this.tpos = this.pageOrigin || hs.getPosition(a), hs.getPageSize(), (this.x = new hs.Dimension(this, "x")).calcThumb(), (this.y = new hs.Dimension(this, "y")).calcThumb(), /area/i.test(a.tagName) && this.getImageMapAreaCorrection(a), this.wrapper = hs.createElement("div", {
                    id: "highslide-wrapper-" + this.key,
                    className: "highslide-wrapper " + this.wrapperClassName
                }, {
                    visibility: "hidden",
                    position: "absolute",
                    zIndex: hs.zIndexCounter += 2
                }, null, !0), this.wrapper.onmouseover = this.wrapper.onmouseout = hs.wrapperMouseHandler, "image" == this.contentType && 2 == this.outlineWhileAnimating && (this.outlineWhileAnimating = 0), !this.outlineType || this.last && this.isImage && "crossfade" == this.transitions[1]) this[this.contentType + "Create"]();
            else if (hs.pendingOutlines[this.outlineType]) this.connectOutline(), this[this.contentType + "Create"]();
            else {
                this.showLoading();
                var r = this;
                new hs.Outline(this.outlineType, function() {
                    r.connectOutline(), r[r.contentType + "Create"]()
                })
            }
            return !0
        }
        hs.addEventListener(document, "ready", function() {
            new hs.Expander(e, t, s, i)
        })
    }, hs.Expander.prototype = {
        error: function(e) {
            hs.debug ? alert("Line " + e.lineNumber + ": " + e.message) : window.location.href = this.src
        },
        connectOutline: function() {
            var e = this.outline = hs.pendingOutlines[this.outlineType];
            e.exp = this, e.table.style.zIndex = this.wrapper.style.zIndex - 1, hs.pendingOutlines[this.outlineType] = null
        },
        showLoading: function() {
            if (!this.onLoadStarted && !this.loading) {
                this.loading = hs.loading;
                var e = this;
                if (this.loading.onclick = function() {
                        e.cancelLoading()
                    }, hs.fireEvent(this, "onShowLoading")) {
                    e = this;
                    var t = this.x.get("loadingPos") + "px",
                        s = this.y.get("loadingPos") + "px";
                    if (!i && this.last && "crossfade" == this.transitions[1]) var i = this.last;
                    i && (t = i.x.get("loadingPosXfade") + "px", s = i.y.get("loadingPosXfade") + "px", this.loading.style.zIndex = hs.zIndexCounter++), setTimeout(function() {
                        e.loading && hs.setStyles(e.loading, {
                            left: t,
                            top: s,
                            zIndex: hs.zIndexCounter++
                        })
                    }, 100)
                }
            }
        },
        imageCreate: function() {
            var e = this,
                t = document.createElement("img");
            this.content = t, t.onload = function() {
                hs.expanders[e.key] && e.contentLoaded()
            }, hs.blockRightClick && (t.oncontextmenu = function() {
                return !1
            }), t.className = "highslide-image", hs.setStyles(t, {
                visibility: "hidden",
                display: "block",
                position: "absolute",
                maxWidth: "9999px",
                zIndex: 3
            }), t.title = hs.lang.restoreTitle, hs.safari && hs.container.appendChild(t), hs.ie && hs.flushImgSize && (t.src = null), t.src = this.src, this.showLoading()
        },
        htmlCreate: function() {
            if (hs.fireEvent(this, "onBeforeGetContent")) {
                if (this.content = hs.getCacheBinding(this.a), this.content || (this.content = hs.getNode(this.contentId)), this.content || (this.content = hs.getSelfRendered()), this.getInline(["maincontent"]), this.maincontent) {
                    var e = hs.getElementByClass(this.content, "div", "highslide-body");
                    e && e.appendChild(this.maincontent), this.maincontent.style.display = "block"
                }
                hs.fireEvent(this, "onAfterGetContent");
                var t = this.innerContent = this.content;
                if (/(swf|iframe)/.test(this.objectType) && this.setObjContainerSize(t), hs.container.appendChild(this.wrapper), hs.setStyles(this.wrapper, {
                        position: "static",
                        padding: "0 " + hs.marginRight + "px 0 " + hs.marginLeft + "px"
                    }), this.content = hs.createElement("div", {
                        className: "highslide-html"
                    }, {
                        position: "relative",
                        zIndex: 3,
                        height: 0,
                        overflow: "hidden"
                    }, this.wrapper), this.mediumContent = hs.createElement("div", null, null, this.content, 1), this.mediumContent.appendChild(t), hs.setStyles(t, {
                        position: "relative",
                        display: "block",
                        direction: hs.lang.cssDirection || ""
                    }), this.width && (t.style.width = this.width + "px"), this.height && hs.setStyles(t, {
                        height: this.height + "px",
                        overflow: "hidden"
                    }), t.offsetWidth < this.minWidth && (t.style.width = this.minWidth + "px"), "ajax" != this.objectType || hs.getCacheBinding(this.a)) "iframe" == this.objectType && "before" == this.objectLoadTime ? this.writeExtendedContent() : this.contentLoaded();
                else {
                    this.showLoading();
                    var s = this,
                        i = new hs.Ajax(this.a, t);
                    i.src = this.src, i.onLoad = function() {
                        hs.expanders[s.key] && s.contentLoaded()
                    }, i.onError = function() {
                        location.href = s.src
                    }, i.run()
                }
            }
        },
        contentLoaded: function() {
            try {
                if (!this.content) return;
                if (this.content.onload = null, this.onLoadStarted) return;
                this.onLoadStarted = !0;
                var e = this.x,
                    t = this.y;
                this.loading && (hs.setStyles(this.loading, {
                    top: "-9999px"
                }), this.loading = null, hs.fireEvent(this, "onHideLoading")), this.isImage ? (e.full = this.content.width, t.full = this.content.height, hs.setStyles(this.content, {
                    width: e.t + "px",
                    height: t.t + "px"
                }), this.wrapper.appendChild(this.content), hs.container.appendChild(this.wrapper)) : this.htmlGetSize && this.htmlGetSize(), e.calcBorders(), t.calcBorders(), hs.setStyles(this.wrapper, {
                    left: e.tpos + e.tb - e.cb + "px",
                    top: t.tpos + e.tb - t.cb + "px"
                }), this.initSlideshow(), this.getOverlays();
                var s = e.full / t.full;
                if (e.calcExpanded(), this.justify(e), t.calcExpanded(), this.justify(t), this.isHtml && this.htmlSizeOperations(), this.overlayBox && this.sizeOverlayBox(0, 1), this.allowSizeReduction) {
                    this.isImage ? this.correctRatio(s) : this.fitOverlayBox();
                    var i = this.slideshow;
                    if (i && this.last && i.controls && i.fixedControls) {
                        var h, n = i.overlayOptions.position || "";
                        for (var o in hs.oPos)
                            for (var a = 0; a < 5; a++) h = this[o], n.match(hs.oPos[o][a]) && (h.pos = this.last[o].pos + (this.last[o].p1 - h.p1) + (this.last[o].size - h.size) * [0, 0, .5, 1, 1][a], "fit" == i.fixedControls && (h.pos + h.size + h.p1 + h.p2 > h.scroll + h.clientSize - h.marginMax && (h.pos = h.scroll + h.clientSize - h.size - h.marginMin - h.marginMax - h.p1 - h.p2), h.pos < h.scroll + h.marginMin && (h.pos = h.scroll + h.marginMin)))
                    }
                    this.isImage && this.x.full > (this.x.imgSize || this.x.size) && (this.createFullExpand(), 1 == this.overlays.length && this.sizeOverlayBox())
                }
                this.show()
            } catch (e) {
                this.error(e)
            }
        },
        setObjContainerSize: function(e, t) {
            var s = hs.getElementByClass(e, "DIV", "highslide-body");
            /(iframe|swf)/.test(this.objectType) && (this.objectWidth && (s.style.width = this.objectWidth + "px"), this.objectHeight && (s.style.height = this.objectHeight + "px"))
        },
        writeExtendedContent: function() {
            if (!this.hasExtendedContent) {
                if (this.body = hs.getElementByClass(this.innerContent, "DIV", "highslide-body"), "iframe" == this.objectType) {
                    this.showLoading();
                    var e = hs.clearing.cloneNode(1);
                    this.body.appendChild(e), this.newWidth = this.innerContent.offsetWidth, this.objectWidth || (this.objectWidth = e.offsetWidth);
                    var t = this.innerContent.offsetHeight - this.body.offsetHeight,
                        s = this.objectHeight || hs.page.height - t - hs.marginTop - hs.marginBottom,
                        i = "before" == this.objectLoadTime ? ' onload="if (hs.expanders[' + this.key + "]) hs.expanders[" + this.key + '].contentLoaded()" ' : "";
                    this.body.innerHTML += '<iframe name="hs' + (new Date).getTime() + '" frameborder="0" key="' + this.key + '"  style="width:' + this.objectWidth + "px; height:" + s + 'px" ' + i + ' src="' + this.src + '" ></iframe>', this.ruler = this.body.getElementsByTagName("div")[0], this.iframe = this.body.getElementsByTagName("iframe")[0], "after" == this.objectLoadTime && this.correctIframeSize()
                }
                if ("swf" == this.objectType) {
                    this.body.id = this.body.id || "hs-flash-id-" + this.key;
                    var h = this.swfOptions;
                    h.params || (h.params = {}), void 0 === h.params.wmode && (h.params.wmode = "transparent"), swfobject && swfobject.embedSWF(this.src, this.body.id, this.objectWidth, this.objectHeight, h.version || "7", h.expressInstallSwfurl, h.flashvars, h.params, h.attributes)
                }
                this.hasExtendedContent = !0
            }
        },
        htmlGetSize: function() {
            this.iframe && !this.objectHeight && (this.iframe.style.height = this.body.style.height = this.getIframePageHeight() + "px"), this.innerContent.appendChild(hs.clearing), this.x.full || (this.x.full = this.innerContent.offsetWidth), this.y.full = this.innerContent.offsetHeight, this.innerContent.removeChild(hs.clearing), hs.ie && this.newHeight > parseInt(this.innerContent.currentStyle.height) && (this.newHeight = parseInt(this.innerContent.currentStyle.height)), hs.setStyles(this.wrapper, {
                position: "absolute",
                padding: "0"
            }), hs.setStyles(this.content, {
                width: this.x.t + "px",
                height: this.y.t + "px"
            })
        },
        getIframePageHeight: function() {
            var e;
            try {
                var t = this.iDoc = this.iframe.contentDocument || this.iframe.contentWindow.document,
                    s = t.createElement("div");
                s.style.clear = "both", t.body.appendChild(s), e = s.offsetTop, hs.ie && (e += parseInt(t.body.currentStyle.marginTop) + parseInt(t.body.currentStyle.marginBottom) - 1)
            } catch (t) {
                e = 300
            }
            return e
        },
        correctIframeSize: function() {
            var e = this.innerContent.offsetWidth - this.ruler.offsetWidth;
            hs.discardElement(this.ruler), e < 0 && (e = 0);
            var t = this.innerContent.offsetHeight - this.iframe.offsetHeight;
            if (this.iDoc && !this.objectHeight && !this.height && this.y.size == this.y.full) try {
                this.iDoc.body.style.overflow = "hidden"
            } catch (e) {}
            hs.setStyles(this.iframe, {
                width: Math.abs(this.x.size - e) + "px",
                height: Math.abs(this.y.size - t) + "px"
            }), hs.setStyles(this.body, {
                width: this.iframe.style.width,
                height: this.iframe.style.height
            }), this.scrollingContent = this.iframe, this.scrollerDiv = this.scrollingContent
        },
        htmlSizeOperations: function() {
            this.setObjContainerSize(this.innerContent), "swf" == this.objectType && "before" == this.objectLoadTime && this.writeExtendedContent(), this.x.size < this.x.full && !this.allowWidthReduction && (this.x.size = this.x.full), this.y.size < this.y.full && !this.allowHeightReduction && (this.y.size = this.y.full), this.scrollerDiv = this.innerContent, hs.setStyles(this.mediumContent, {
                position: "relative",
                width: this.x.size + "px"
            }), hs.setStyles(this.innerContent, {
                border: "none",
                width: "auto",
                height: "auto"
            });
            var e = hs.getElementByClass(this.innerContent, "DIV", "highslide-body");
            if (e && !/(iframe|swf)/.test(this.objectType)) {
                var t = e;
                e = hs.createElement(t.nodeName, null, {
                    overflow: "hidden"
                }, null, !0), t.parentNode.insertBefore(e, t), e.appendChild(hs.clearing), e.appendChild(t);
                var s = this.innerContent.offsetWidth - e.offsetWidth,
                    i = this.innerContent.offsetHeight - e.offsetHeight;
                e.removeChild(hs.clearing);
                var h = hs.safari || "KDE" == navigator.vendor ? 1 : 0;
                hs.setStyles(e, {
                    width: this.x.size - s - h + "px",
                    height: this.y.size - i + "px",
                    overflow: "auto",
                    position: "relative"
                }), h && t.offsetHeight > e.offsetHeight && (e.style.width = parseInt(e.style.width) + h + "px"), this.scrollingContent = e, this.scrollerDiv = this.scrollingContent
            }
            this.iframe && "before" == this.objectLoadTime && this.correctIframeSize(), !this.scrollingContent && this.y.size < this.mediumContent.offsetHeight && (this.scrollerDiv = this.content), this.scrollerDiv != this.content || this.allowWidthReduction || /(iframe|swf)/.test(this.objectType) || (this.x.size += 17), this.scrollerDiv && this.scrollerDiv.offsetHeight > this.scrollerDiv.parentNode.offsetHeight && setTimeout("try { hs.expanders[" + this.key + "].scrollerDiv.style.overflow = 'auto'; } catch(e) {}", hs.expandDuration)
        },
        getImageMapAreaCorrection: function(e) {
            for (var t = e.coords.split(","), s = 0; s < t.length; s++) t[s] = parseInt(t[s]);
            if ("circle" == e.shape.toLowerCase()) this.x.tpos += t[0] - t[2], this.y.tpos += t[1] - t[2], this.x.t = this.y.t = 2 * t[2];
            else {
                var i, h, n = i = t[0],
                    o = h = t[1];
                for (s = 0; s < t.length; s++) s % 2 == 0 ? (n = Math.min(n, t[s]), i = Math.max(i, t[s])) : (o = Math.min(o, t[s]), h = Math.max(h, t[s]));
                this.x.tpos += n, this.x.t = i - n, this.y.tpos += o, this.y.t = h - o
            }
        },
        justify: function(e, t) {
            var s, i = e.target,
                h = e == this.x ? "x" : "y";
            if (i && i.match(/ /) && (s = i.split(" "), i = s[0]), i && hs.$(i)) e.pos = hs.getPosition(hs.$(i))[h], s && s[1] && s[1].match(/^[-]?[0-9]+px$/) && (e.pos += parseInt(s[1])), e.size < e.minSize && (e.size = e.minSize);
            else if ("auto" == e.justify || "center" == e.justify) {
                var n = !1,
                    o = e.exp.allowSizeReduction;
                "center" == e.justify ? e.pos = Math.round(e.scroll + (e.clientSize + e.marginMin - e.marginMax - e.get("wsize")) / 2) : e.pos = Math.round(e.pos - (e.get("wsize") - e.t) / 2), e.pos < e.scroll + e.marginMin && (e.pos = e.scroll + e.marginMin, n = !0), !t && e.size < e.minSize && (e.size = e.minSize, o = !1), e.pos + e.get("wsize") > e.scroll + e.clientSize - e.marginMax && (!t && n && o ? e.size = Math.min(e.size, e.get("y" == h ? "fitsize" : "maxsize")) : e.get("wsize") < e.get("fitsize") ? e.pos = e.scroll + e.clientSize - e.marginMax - e.get("wsize") : (e.pos = e.scroll + e.marginMin, !t && o && (e.size = e.get("y" == h ? "fitsize" : "maxsize")))), !t && e.size < e.minSize && (e.size = e.minSize, o = !1)
            } else "max" == e.justify && (e.pos = Math.floor(e.pos - e.size + e.t));
            if (e.pos < e.marginMin) {
                var a = e.pos;
                e.pos = e.marginMin, o && !t && (e.size = e.size - (e.pos - a))
            }
        },
        correctRatio: function(e) {
            var t = this.x,
                s = this.y,
                i = !1,
                h = Math.min(t.full, t.size),
                n = Math.min(s.full, s.size),
                o = this.useBox || hs.padToMinWidth;
            h / n > e ? ((h = n * e) < t.minSize && (n = (h = t.minSize) / e), i = !0) : h / n < e && (n = h / e, i = !0), hs.padToMinWidth && t.full < t.minSize ? (t.imgSize = t.full, s.size = s.imgSize = s.full) : this.useBox ? (t.imgSize = h, s.imgSize = n) : (t.size = h, s.size = n), i = this.fitOverlayBox(o ? null : e, i), o && s.size < s.imgSize && (s.imgSize = s.size, t.imgSize = s.size * e), (i || o) && (t.pos = t.tpos - t.cb + t.tb, t.minSize = t.size, this.justify(t, !0), s.pos = s.tpos - s.cb + s.tb, s.minSize = s.size, this.justify(s, !0), this.overlayBox && this.sizeOverlayBox())
        },
        fitOverlayBox: function(e, t) {
            var s = this.x,
                i = this.y;
            if (this.overlayBox && (this.isImage || this.allowHeightReduction))
                for (; i.size > this.minHeight && s.size > this.minWidth && i.get("wsize") > i.get("fitsize");) i.size -= 10, e && (s.size = i.size * e), this.sizeOverlayBox(0, 1), t = !0;
            return t
        },
        reflow: function() {
            if (this.scrollerDiv) {
                var e = /iframe/i.test(this.scrollerDiv.tagName) ? this.getIframePageHeight() + 1 + "px" : "auto";
                this.body && (this.body.style.height = e), this.scrollerDiv.style.height = e, this.y.setSize(this.innerContent.offsetHeight)
            }
        },
        show: function() {
            var e = this.x,
                t = this.y;
            this.doShowHide("hidden"), hs.fireEvent(this, "onBeforeExpand"), this.slideshow && this.slideshow.thumbstrip && this.slideshow.thumbstrip.selectThumb(), this.changeSize(1, {
                wrapper: {
                    width: e.get("wsize"),
                    height: t.get("wsize"),
                    left: e.pos,
                    top: t.pos
                },
                content: {
                    left: e.p1 + e.get("imgPad"),
                    top: t.p1 + t.get("imgPad"),
                    width: e.imgSize || e.size,
                    height: t.imgSize || t.size
                }
            }, hs.expandDuration)
        },
        changeSize: function(e, t, s) {
            var i = this.transitions,
                h = e ? this.last ? this.last.a : null : hs.upcoming,
                n = i[1] && h && hs.getParam(h, "transitions")[1] == i[1] ? i[1] : i[0];
            if (this[n] && "expand" != n) this[n](e, t);
            else {
                this.outline && !this.outlineWhileAnimating && (e ? this.outline.setPosition() : this.outline.destroy(this.isHtml && this.preserveContent)), e || this.destroyOverlays();
                var o = this,
                    a = o.x,
                    r = o.y,
                    l = this.easing;
                e || (l = this.easingClose || l);
                var d = e ? function() {
                    o.outline && (o.outline.table.style.visibility = "visible"), setTimeout(function() {
                        o.afterExpand()
                    }, 50)
                } : function() {
                    o.afterClose()
                };
                e && hs.setStyles(this.wrapper, {
                    width: a.t + "px",
                    height: r.t + "px"
                }), e && this.isHtml && hs.setStyles(this.wrapper, {
                    left: a.tpos - a.cb + a.tb + "px",
                    top: r.tpos - r.cb + r.tb + "px"
                }), this.fadeInOut && (hs.setStyles(this.wrapper, {
                    opacity: e ? 0 : 1
                }), hs.extend(t.wrapper, {
                    opacity: e
                })), hs.animate(this.wrapper, t.wrapper, {
                    duration: s,
                    easing: l,
                    step: function(t, s) {
                        if (o.outline && o.outlineWhileAnimating && "top" == s.prop) {
                            var i = e ? s.pos : 1 - s.pos,
                                h = {
                                    w: a.t + (a.get("wsize") - a.t) * i,
                                    h: r.t + (r.get("wsize") - r.t) * i,
                                    x: a.tpos + (a.pos - a.tpos) * i,
                                    y: r.tpos + (r.pos - r.tpos) * i
                                };
                            o.outline.setPosition(h, 0, 1)
                        }
                        o.isHtml && ("left" == s.prop && (o.mediumContent.style.left = a.pos - t + "px"), "top" == s.prop && (o.mediumContent.style.top = r.pos - t + "px"))
                    }
                }), hs.animate(this.content, t.content, s, l, d), e && (this.wrapper.style.visibility = "visible", this.content.style.visibility = "visible", this.isHtml && (this.innerContent.style.visibility = "visible"))
            }
        },
        fade: function(e, t) {
            this.outlineWhileAnimating = !1;
            var s = this,
                i = e ? hs.expandDuration : 0;
            if (e && (hs.animate(this.wrapper, t.wrapper, 0), hs.setStyles(this.wrapper, {
                    opacity: 0,
                    visibility: "visible"
                }), hs.animate(this.content, t.content, 0), this.content.style.visibility = "visible", hs.animate(this.wrapper, {
                    opacity: 1
                }, i, null, function() {
                    s.afterExpand()
                })), this.outline) {
                this.outline.table.style.zIndex = this.wrapper.style.zIndex;
                for (var h = e || -1, n = this.outline.offset, o = e ? 3 : n, a = e ? n : 3, r = o; h * r <= h * a; r += h, i += 25) ! function() {
                    var t = e ? a - r : o - r;
                    setTimeout(function() {
                        s.outline.setPosition(0, t, 1)
                    }, i)
                }()
            }
            e || setTimeout(function() {
                s.outline && s.outline.destroy(s.preserveContent), s.destroyOverlays(), hs.animate(s.wrapper, {
                    opacity: 0
                }, hs.restoreDuration, null, function() {
                    s.afterClose()
                })
            }, i)
        },
        crossfade: function(e, t, s) {
            if (e) {
                var i = this,
                    h = this.last,
                    n = this.x,
                    o = this.y,
                    a = h.x,
                    r = h.y,
                    l = this.wrapper,
                    d = this.content,
                    p = this.overlayBox;
                hs.removeEventListener(document, "mousemove", hs.dragHandler), hs.setStyles(d, {
                    width: (n.imgSize || n.size) + "px",
                    height: (o.imgSize || o.size) + "px"
                }), p && (p.style.overflow = "visible"), this.outline = h.outline, this.outline && (this.outline.exp = i), h.outline = null;
                var c = hs.createElement("div", {
                        className: "highslide-" + this.contentType
                    }, {
                        position: "absolute",
                        zIndex: 4,
                        overflow: "hidden",
                        display: "none"
                    }),
                    u = {
                        oldImg: h,
                        newImg: this
                    };
                for (var g in u) this[g] = u[g].content.cloneNode(1), hs.setStyles(this[g], {
                    position: "absolute",
                    border: 0,
                    visibility: "visible"
                }), c.appendChild(this[g]);
                if (l.appendChild(c), this.isHtml && hs.setStyles(this.mediumContent, {
                        left: 0,
                        top: 0
                    }), p && (p.className = "", l.appendChild(p)), c.style.display = "", h.content.style.display = "none", hs.safari) {
                    var f = navigator.userAgent.match(/Safari\/([0-9]{3})/);
                    f && parseInt(f[1]) < 525 && (this.wrapper.style.visibility = "visible")
                }
                hs.animate(l, {
                    width: n.size
                }, {
                    duration: hs.transitionDuration,
                    step: function(e, t) {
                        var s, u = t.pos,
                            g = 1 - u,
                            f = {},
                            m = ["pos", "size", "p1", "p2"];
                        for (var y in m) f["x" + (s = m[y])] = Math.round(g * a[s] + u * n[s]), f["y" + s] = Math.round(g * r[s] + u * o[s]), f.ximgSize = Math.round(g * (a.imgSize || a.size) + u * (n.imgSize || n.size)), f.ximgPad = Math.round(g * a.get("imgPad") + u * n.get("imgPad")), f.yimgSize = Math.round(g * (r.imgSize || r.size) + u * (o.imgSize || o.size)), f.yimgPad = Math.round(g * r.get("imgPad") + u * o.get("imgPad"));
                        i.outline && i.outline.setPosition({
                            x: f.xpos,
                            y: f.ypos,
                            w: f.xsize + f.xp1 + f.xp2 + 2 * n.cb,
                            h: f.ysize + f.yp1 + f.yp2 + 2 * o.cb
                        }), h.wrapper.style.clip = "rect(" + (f.ypos - r.pos) + "px, " + (f.xsize + f.xp1 + f.xp2 + f.xpos + 2 * a.cb - a.pos) + "px, " + (f.ysize + f.yp1 + f.yp2 + f.ypos + 2 * r.cb - r.pos) + "px, " + (f.xpos - a.pos) + "px)", hs.setStyles(d, {
                            top: f.yp1 + o.get("imgPad") + "px",
                            left: f.xp1 + n.get("imgPad") + "px",
                            marginTop: o.pos - f.ypos + "px",
                            marginLeft: n.pos - f.xpos + "px"
                        }), hs.setStyles(l, {
                            top: f.ypos + "px",
                            left: f.xpos + "px",
                            width: f.xp1 + f.xp2 + f.xsize + 2 * n.cb + "px",
                            height: f.yp1 + f.yp2 + f.ysize + 2 * o.cb + "px"
                        }), hs.setStyles(c, {
                            width: (f.ximgSize || f.xsize) + "px",
                            height: (f.yimgSize || f.ysize) + "px",
                            left: f.xp1 + f.ximgPad + "px",
                            top: f.yp1 + f.yimgPad + "px",
                            visibility: "visible"
                        }), hs.setStyles(i.oldImg, {
                            top: r.pos - f.ypos + r.p1 - f.yp1 + r.get("imgPad") - f.yimgPad + "px",
                            left: a.pos - f.xpos + a.p1 - f.xp1 + a.get("imgPad") - f.ximgPad + "px"
                        }), hs.setStyles(i.newImg, {
                            opacity: u,
                            top: o.pos - f.ypos + o.p1 - f.yp1 + o.get("imgPad") - f.yimgPad + "px",
                            left: n.pos - f.xpos + n.p1 - f.xp1 + n.get("imgPad") - f.ximgPad + "px"
                        }), p && hs.setStyles(p, {
                            width: f.xsize + "px",
                            height: f.ysize + "px",
                            left: f.xp1 + n.cb + "px",
                            top: f.yp1 + o.cb + "px"
                        })
                    },
                    complete: function() {
                        l.style.visibility = d.style.visibility = "visible", d.style.display = "block", hs.discardElement(c), i.afterExpand(), h.afterClose(), i.last = null
                    }
                })
            }
        },
        reuseOverlay: function(e, t) {
            if (!this.last) return !1;
            for (var s = 0; s < this.last.overlays.length; s++) {
                var i = hs.$("hsId" + this.last.overlays[s]);
                if (i && i.hsId == e.hsId) return this.genOverlayBox(), i.reuse = this.key, hs.push(this.overlays, this.last.overlays[s]), !0
            }
            return !1
        },
        afterExpand: function() {
            if (this.isExpanded = !0, this.a.className += " highslide-active-anchor", this.focus(), this.isHtml && "after" == this.objectLoadTime && this.writeExtendedContent(), this.iframe) {
                try {
                    var e = this,
                        t = this.iframe.contentDocument || this.iframe.contentWindow.document;
                    hs.addEventListener(t, "mousedown", function() {
                        hs.focusKey != e.key && e.focus()
                    })
                } catch (e) {}
                hs.ie && "boolean" != typeof this.isClosing && (this.iframe.style.width = this.objectWidth - 1 + "px")
            }
            this.dimmingOpacity && hs.dim(this), hs.upcoming && hs.upcoming == this.a && (hs.upcoming = null), this.prepareNextOutline();
            var s = hs.page,
                i = hs.mouse.x + s.scrollLeft,
                h = hs.mouse.y + s.scrollTop;
            this.mouseIsOver = this.x.pos < i && i < this.x.pos + this.x.get("wsize") && this.y.pos < h && h < this.y.pos + this.y.get("wsize"), this.overlayBox && this.showOverlays(), hs.fireEvent(this, "onAfterExpand")
        },
        prepareNextOutline: function() {
            var e = this.key,
                t = this.outlineType;
            new hs.Outline(t, function() {
                try {
                    hs.expanders[e].preloadNext()
                } catch (e) {}
            })
        },
        preloadNext: function() {
            var e = this.getAdjacentAnchor(1);
            e && e.onclick.toString().match(/hs\.expand/) && hs.createElement("img", {
                src: hs.getSrc(e)
            })
        },
        getAdjacentAnchor: function(e) {
            var t = this.getAnchorIndex(),
                s = hs.anchors.groups[this.slideshowGroup || "none"];
            if (!s[t + e] && this.slideshow && this.slideshow.repeat) {
                if (1 == e) return s[0];
                if (-1 == e) return s[s.length - 1]
            }
            return s[t + e] || null
        },
        getAnchorIndex: function() {
            var e = hs.getAnchors().groups[this.slideshowGroup || "none"];
            if (e)
                for (var t = 0; t < e.length; t++)
                    if (e[t] == this.a) return t;
            return null
        },
        getNumber: function() {
            if (this[this.numberPosition]) {
                var e = hs.anchors.groups[this.slideshowGroup || "none"];
                if (e) {
                    var t = hs.lang.number.replace("%1", this.getAnchorIndex() + 1).replace("%2", e.length);
                    this[this.numberPosition].innerHTML = '<div class="highslide-number">' + t + "</div>" + this[this.numberPosition].innerHTML
                }
            }
        },
        initSlideshow: function() {
            if (this.last) this.slideshow = this.last.slideshow;
            else
                for (var e = 0; e < hs.slideshows.length; e++) {
                    var t = (s = hs.slideshows[e]).slideshowGroup;
                    null != t && t !== this.slideshowGroup || (this.slideshow = new hs.Slideshow(this.key, s))
                }
            var s;
            if (s = this.slideshow) {
                var i = s.expKey = this.key;
                s.checkFirstAndLast(), s.disable("full-expand"), s.controls && this.createOverlay(hs.extend(s.overlayOptions || {}, {
                    overlayId: s.controls,
                    hsId: "controls",
                    zIndex: 5
                })), s.thumbstrip && s.thumbstrip.add(this), !this.last && this.autoplay && s.play(!0), s.autoplay && (s.autoplay = setTimeout(function() {
                    hs.next(i)
                }, s.interval || 500))
            }
        },
        cancelLoading: function() {
            hs.discardElement(this.wrapper), hs.expanders[this.key] = null, hs.upcoming == this.a && (hs.upcoming = null), hs.undim(this.key), this.loading && (hs.loading.style.left = "-9999px"), hs.fireEvent(this, "onHideLoading")
        },
        writeCredits: function() {
            this.credits || (this.credits = hs.createElement("a", {
                href: hs.creditsHref,
                target: hs.creditsTarget,
                className: "highslide-credits",
                innerHTML: hs.lang.creditsText,
                title: hs.lang.creditsTitle
            }), this.createOverlay({
                overlayId: this.credits,
                position: this.creditsPosition || "top left",
                hsId: "credits"
            }))
        },
        getInline: function(types, addOverlay) {
            for (var i = 0; i < types.length; i++) {
                var type = types[i],
                    s = null;
                if ("caption" == type && !hs.fireEvent(this, "onBeforeGetCaption")) return;
                if ("heading" == type && !hs.fireEvent(this, "onBeforeGetHeading")) return;
                if (!this[type + "Id"] && this.thumbsUserSetId && (this[type + "Id"] = type + "-for-" + this.thumbsUserSetId), this[type + "Id"] && (this[type] = hs.getNode(this[type + "Id"])), !this[type] && !this[type + "Text"] && this[type + "Eval"]) try {
                    s = eval(this[type + "Eval"])
                } catch (e) {}
                if (!this[type] && this[type + "Text"] && (s = this[type + "Text"]), !this[type] && !s && (this[type] = hs.getNode(this.a["_" + type + "Id"]), !this[type]))
                    for (var next = this.a.nextSibling; next && !hs.isHsAnchor(next);) {
                        if (new RegExp("highslide-" + type).test(next.className || null)) {
                            next.id || (this.a["_" + type + "Id"] = next.id = "hsId" + hs.idCounter++), this[type] = hs.getNode(next.id);
                            break
                        }
                        next = next.nextSibling
                    }
                if (this[type] || s || this.numberPosition != type || (s = "\n"), !this[type] && s && (this[type] = hs.createElement("div", {
                        className: "highslide-" + type,
                        innerHTML: s
                    })), addOverlay && this[type]) {
                    var o = {
                        position: "heading" == type ? "above" : "below"
                    };
                    for (var x in this[type + "Overlay"]) o[x] = this[type + "Overlay"][x];
                    o.overlayId = this[type], this.createOverlay(o)
                }
            }
        },
        doShowHide: function(e) {
            hs.hideSelects && this.showHideElements("SELECT", e), hs.hideIframes && this.showHideElements("IFRAME", e), hs.geckoMac && this.showHideElements("*", e)
        },
        showHideElements: function(e, t) {
            for (var s = document.getElementsByTagName(e), i = "*" == e ? "overflow" : "visibility", h = 0; h < s.length; h++)
                if ("visibility" == i || "auto" == document.defaultView.getComputedStyle(s[h], "").getPropertyValue("overflow") || null != s[h].getAttribute("hidden-by")) {
                    var n = s[h].getAttribute("hidden-by");
                    if ("visible" == t && n) n = n.replace("[" + this.key + "]", ""), s[h].setAttribute("hidden-by", n), n || (s[h].style[i] = s[h].origProp);
                    else if ("hidden" == t) {
                        var o = hs.getPosition(s[h]);
                        if (o.w = s[h].offsetWidth, o.h = s[h].offsetHeight, !this.dimmingOpacity) var a = o.x + o.w < this.x.get("opos") || o.x > this.x.get("opos") + this.x.get("osize"),
                            r = o.y + o.h < this.y.get("opos") || o.y > this.y.get("opos") + this.y.get("osize");
                        var l = hs.getWrapperKey(s[h]);
                        a || r || l == this.key ? n != "[" + this.key + "]" && hs.focusKey != l || l == this.key ? n && n.indexOf("[" + this.key + "]") > -1 && s[h].setAttribute("hidden-by", n.replace("[" + this.key + "]", "")) : (s[h].setAttribute("hidden-by", ""), s[h].style[i] = s[h].origProp || "") : n ? -1 == n.indexOf("[" + this.key + "]") && s[h].setAttribute("hidden-by", n + "[" + this.key + "]") : (s[h].setAttribute("hidden-by", "[" + this.key + "]"), s[h].origProp = s[h].style[i], s[h].style[i] = "hidden")
                    }
                }
        },
        focus: function() {
            this.wrapper.style.zIndex = hs.zIndexCounter += 2;
            for (var e = 0; e < hs.expanders.length; e++)
                if (hs.expanders[e] && e == hs.focusKey) {
                    var t = hs.expanders[e];
                    t.content.className += " highslide-" + t.contentType + "-blur", t.isImage && (t.content.style.cursor = hs.ie ? "hand" : "pointer", t.content.title = hs.lang.focusTitle), hs.fireEvent(t, "onBlur")
                }
            this.outline && (this.outline.table.style.zIndex = this.wrapper.style.zIndex - 1), this.content.className = "highslide-" + this.contentType, this.isImage && (this.content.title = hs.lang.restoreTitle, hs.restoreCursor && (hs.styleRestoreCursor = window.opera ? "pointer" : "url(" + hs.graphicsDir + hs.restoreCursor + "), pointer", hs.ie && hs.uaVersion < 6 && (hs.styleRestoreCursor = "hand"), this.content.style.cursor = hs.styleRestoreCursor)), hs.focusKey = this.key, hs.addEventListener(document, window.opera ? "keypress" : "keydown", hs.keyHandler), hs.fireEvent(this, "onFocus")
        },
        moveTo: function(e, t) {
            this.x.setPos(e), this.y.setPos(t)
        },
        resize: function(e) {
            var t, s, i = e.width / e.height;
            t = Math.max(e.width + e.dX, Math.min(this.minWidth, this.x.full)), this.isImage && Math.abs(t - this.x.full) < 12 && (t = this.x.full), (s = this.isHtml ? e.height + e.dY : t / i) < Math.min(this.minHeight, this.y.full) && (s = Math.min(this.minHeight, this.y.full), this.isImage && (t = s * i)), this.resizeTo(t, s)
        },
        resizeTo: function(e, t) {
            this.y.setSize(t), this.x.setSize(e), this.wrapper.style.height = this.y.get("wsize") + "px"
        },
        close: function() {
            if (!this.isClosing && this.isExpanded && ("crossfade" == this.transitions[1] && hs.upcoming && (hs.getExpander(hs.upcoming).cancelLoading(), hs.upcoming = null), hs.fireEvent(this, "onBeforeClose"))) {
                this.isClosing = !0, this.slideshow && !hs.upcoming && this.slideshow.pause(), hs.removeEventListener(document, window.opera ? "keypress" : "keydown", hs.keyHandler);
                try {
                    this.isHtml && this.htmlPrepareClose(), this.content.style.cursor = "default", this.changeSize(0, {
                        wrapper: {
                            width: this.x.t,
                            height: this.y.t,
                            left: this.x.tpos - this.x.cb + this.x.tb,
                            top: this.y.tpos - this.y.cb + this.y.tb
                        },
                        content: {
                            left: 0,
                            top: 0,
                            width: this.x.t,
                            height: this.y.t
                        }
                    }, hs.restoreDuration)
                } catch (e) {
                    this.afterClose()
                }
            }
        },
        htmlPrepareClose: function() {
            if (hs.geckoMac && (hs.mask || (hs.mask = hs.createElement("div", null, {
                    position: "absolute"
                }, hs.container)), hs.setStyles(hs.mask, {
                    width: this.x.size + "px",
                    height: this.y.size + "px",
                    left: this.x.pos + "px",
                    top: this.y.pos + "px",
                    display: "block"
                })), "swf" == this.objectType) try {
                hs.$(this.body.id).StopPlay()
            } catch (e) {}
            "after" != this.objectLoadTime || this.preserveContent || this.destroyObject(), this.scrollerDiv && this.scrollerDiv != this.scrollingContent && (this.scrollerDiv.style.overflow = "hidden")
        },
        destroyObject: function() {
            if (hs.ie && this.iframe) try {
                this.iframe.contentWindow.document.body.innerHTML = ""
            } catch (e) {}
            "swf" == this.objectType && swfobject.removeSWF(this.body.id), this.body.innerHTML = ""
        },
        sleep: function() {
            this.outline && (this.outline.table.style.display = "none"), this.releaseMask = null, this.wrapper.style.display = "none", hs.push(hs.sleeping, this)
        },
        awake: function() {
            try {
                if (hs.expanders[this.key] = this, !hs.allowMultipleInstances && hs.focusKey != this.key) try {
                    hs.expanders[hs.focusKey].close()
                } catch (e) {}
                var e = {
                    display: "",
                    zIndex: hs.zIndexCounter++
                };
                hs.setStyles(this.wrapper, e), this.isClosing = !1;
                var t = this.outline || 0;
                t && (this.outlineWhileAnimating || (e.visibility = "hidden"), hs.setStyles(t.table, e)), this.slideshow && this.initSlideshow(), this.show()
            } catch (e) {}
        },
        createOverlay: function(e) {
            var t = e.overlayId,
                s = "viewport" == e.relativeTo && !/panel$/.test(e.position);
            if ("string" == typeof t && (t = hs.getNode(t)), e.html && (t = hs.createElement("div", {
                    innerHTML: e.html
                })), t && "string" != typeof t && hs.fireEvent(this, "onCreateOverlay", {
                    overlay: t
                }) && (t.style.display = "block", e.hsId = e.hsId || e.overlayId, "crossfade" != this.transitions[1] || !this.reuseOverlay(e, t))) {
                this.genOverlayBox();
                var i = e.width && /^[0-9]+(px|%)$/.test(e.width) ? e.width : "auto";
                /^(left|right)panel$/.test(e.position) && !/^[0-9]+px$/.test(e.width) && (i = "200px");
                var h = hs.createElement("div", {
                    id: "hsId" + hs.idCounter++,
                    hsId: e.hsId
                }, {
                    position: "absolute",
                    visibility: "hidden",
                    width: i,
                    direction: hs.lang.cssDirection || "",
                    opacity: 0
                }, s ? hs.viewport : this.overlayBox, !0);
                s && (h.hsKey = this.key), h.appendChild(t), hs.extend(h, {
                    opacity: 1,
                    offsetX: 0,
                    offsetY: 0,
                    dur: 0 === e.fade || !1 === e.fade || 2 == e.fade && hs.ie ? 0 : 250
                }), hs.extend(h, e), this.gotOverlays && (this.positionOverlay(h), h.hideOnMouseOut && !this.mouseIsOver || hs.animate(h, {
                    opacity: h.opacity
                }, h.dur)), hs.push(this.overlays, hs.idCounter - 1)
            }
        },
        positionOverlay: function(e) {
            var t = e.position || "middle center",
                s = "viewport" == e.relativeTo,
                i = e.offsetX,
                h = e.offsetY;
            s ? (hs.viewport.style.display = "block", e.hsKey = this.key, e.offsetWidth > e.parentNode.offsetWidth && (e.style.width = "100%")) : e.parentNode != this.overlayBox && this.overlayBox.appendChild(e), /left$/.test(t) && (e.style.left = i + "px"), /center$/.test(t) && hs.setStyles(e, {
                left: "50%",
                marginLeft: i - Math.round(e.offsetWidth / 2) + "px"
            }), /right$/.test(t) && (e.style.right = -i + "px"), /^leftpanel$/.test(t) ? (hs.setStyles(e, {
                right: "100%",
                marginRight: this.x.cb + "px",
                top: -this.y.cb + "px",
                bottom: -this.y.cb + "px",
                overflow: "auto"
            }), this.x.p1 = e.offsetWidth) : /^rightpanel$/.test(t) && (hs.setStyles(e, {
                left: "100%",
                marginLeft: this.x.cb + "px",
                top: -this.y.cb + "px",
                bottom: -this.y.cb + "px",
                overflow: "auto"
            }), this.x.p2 = e.offsetWidth);
            var n = e.parentNode.offsetHeight;
            e.style.height = "auto", s && e.offsetHeight > n && (e.style.height = hs.ieLt7 ? n + "px" : "100%"), /^top/.test(t) && (e.style.top = h + "px"), /^middle/.test(t) && hs.setStyles(e, {
                top: "50%",
                marginTop: h - Math.round(e.offsetHeight / 2) + "px"
            }), /^bottom/.test(t) && (e.style.bottom = -h + "px"), /^above$/.test(t) ? (hs.setStyles(e, {
                left: -this.x.p1 - this.x.cb + "px",
                right: -this.x.p2 - this.x.cb + "px",
                bottom: "100%",
                marginBottom: this.y.cb + "px",
                width: "auto"
            }), this.y.p1 = e.offsetHeight) : /^below$/.test(t) && (hs.setStyles(e, {
                position: "relative",
                left: -this.x.p1 - this.x.cb + "px",
                right: -this.x.p2 - this.x.cb + "px",
                top: "100%",
                marginTop: this.y.cb + "px",
                width: "auto"
            }), this.y.p2 = e.offsetHeight, e.style.position = "absolute")
        },
        getOverlays: function() {
            this.getInline(["heading", "caption"], !0), this.getNumber(), this.caption && hs.fireEvent(this, "onAfterGetCaption"), this.heading && hs.fireEvent(this, "onAfterGetHeading"), this.heading && this.dragByHeading && (this.heading.className += " highslide-move"), hs.showCredits && this.writeCredits();
            for (var e = 0; e < hs.overlays.length; e++) {
                var t = (h = hs.overlays[e]).thumbnailId,
                    s = h.slideshowGroup;
                (!t && !s || t && t == this.thumbsUserSetId || s && s === this.slideshowGroup) && (this.isImage || this.isHtml && h.useOnHtml) && this.createOverlay(h)
            }
            var i = [];
            for (e = 0; e < this.overlays.length; e++) {
                var h = hs.$("hsId" + this.overlays[e]);
                /panel$/.test(h.position) ? this.positionOverlay(h) : hs.push(i, h)
            }
            for (e = 0; e < i.length; e++) this.positionOverlay(i[e]);
            this.gotOverlays = !0
        },
        genOverlayBox: function() {
            this.overlayBox || (this.overlayBox = hs.createElement("div", {
                className: this.wrapperClassName
            }, {
                position: "absolute",
                width: (this.x.size || (this.useBox ? this.width : null) || this.x.full) + "px",
                height: (this.y.size || this.y.full) + "px",
                visibility: "hidden",
                overflow: "hidden",
                zIndex: hs.ie ? 4 : "auto"
            }, hs.container, !0))
        },
        sizeOverlayBox: function(e, t) {
            var s = this.overlayBox,
                i = this.x,
                h = this.y;
            if (hs.setStyles(s, {
                    width: i.size + "px",
                    height: h.size + "px"
                }), e || t)
                for (var n = 0; n < this.overlays.length; n++) {
                    var o = hs.$("hsId" + this.overlays[n]),
                        a = hs.ieLt7 || "BackCompat" == document.compatMode;
                    o && /^(above|below)$/.test(o.position) && (a && (o.style.width = s.offsetWidth + 2 * i.cb + i.p1 + i.p2 + "px"), h["above" == o.position ? "p1" : "p2"] = o.offsetHeight), o && a && /^(left|right)panel$/.test(o.position) && (o.style.height = s.offsetHeight + 2 * h.cb + "px")
                }
            e && (hs.setStyles(this.content, {
                top: h.p1 + "px"
            }), hs.setStyles(s, {
                top: h.p1 + h.cb + "px"
            }))
        },
        showOverlays: function() {
            var e = this.overlayBox;
            e.className = "", hs.setStyles(e, {
                top: this.y.p1 + this.y.cb + "px",
                left: this.x.p1 + this.x.cb + "px",
                overflow: "visible"
            }), hs.safari && (e.style.visibility = "visible"), this.wrapper.appendChild(e);
            for (var t = 0; t < this.overlays.length; t++) {
                var s = hs.$("hsId" + this.overlays[t]);
                s.style.zIndex = s.zIndex || 4, s.hideOnMouseOut && !this.mouseIsOver || (s.style.visibility = "visible", hs.setStyles(s, {
                    visibility: "visible",
                    display: ""
                }), hs.animate(s, {
                    opacity: s.opacity
                }, s.dur))
            }
        },
        destroyOverlays: function() {
            if (this.overlays.length) {
                if (this.slideshow) {
                    var e = this.slideshow.controls;
                    e && hs.getExpander(e) == this && e.parentNode.removeChild(e)
                }
                for (var t = 0; t < this.overlays.length; t++) {
                    var s = hs.$("hsId" + this.overlays[t]);
                    s && s.parentNode == hs.viewport && hs.getExpander(s) == this && hs.discardElement(s)
                }
                this.isHtml && this.preserveContent ? (this.overlayBox.style.top = "-9999px", hs.container.appendChild(this.overlayBox)) : hs.discardElement(this.overlayBox)
            }
        },
        createFullExpand: function() {
            this.slideshow && this.slideshow.controls ? this.slideshow.enable("full-expand") : (this.fullExpandLabel = hs.createElement("a", {
                href: "javascript:hs.expanders[" + this.key + "].doFullExpand();",
                title: hs.lang.fullExpandTitle,
                className: "highslide-full-expand"
            }), hs.fireEvent(this, "onCreateFullExpand") && this.createOverlay({
                overlayId: this.fullExpandLabel,
                position: hs.fullExpandPosition,
                hideOnMouseOut: !0,
                opacity: hs.fullExpandOpacity
            }))
        },
        doFullExpand: function() {
            try {
                if (!hs.fireEvent(this, "onDoFullExpand")) return;
                this.fullExpandLabel && hs.discardElement(this.fullExpandLabel), this.focus();
                var e = this.x.size;
                this.resizeTo(this.x.full, this.y.full);
                var t = this.x.pos - (this.x.size - e) / 2;
                t < hs.marginLeft && (t = hs.marginLeft), this.moveTo(t, this.y.pos), this.doShowHide("hidden")
            } catch (e) {
                this.error(e)
            }
        },
        afterClose: function() {
            this.a.className = this.a.className.replace("highslide-active-anchor", ""), this.doShowHide("visible"), this.isHtml && this.preserveContent && "crossfade" != this.transitions[1] ? this.sleep() : (this.outline && this.outlineWhileAnimating && this.outline.destroy(), hs.discardElement(this.wrapper)), hs.mask && (hs.mask.style.display = "none"), this.destroyOverlays(), hs.viewport.childNodes.length || (hs.viewport.style.display = "none"), this.dimmingOpacity && hs.undim(this.key), hs.fireEvent(this, "onAfterClose"), hs.expanders[this.key] = null, hs.reOrder()
        }
    }, hs.Ajax = function(e, t, s) {
        this.a = e, this.content = t, this.pre = s
    }, hs.Ajax.prototype = {
        run: function() {
            var e;
            if (this.src || (this.src = hs.getSrc(this.a)), this.src.match("#")) {
                var t = this.src.split("#");
                this.src = t[0], this.id = t[1]
            }
            if (hs.cachedGets[this.src]) return this.cachedGet = hs.cachedGets[this.src], void(this.id ? this.getElementContent() : this.loadHTML());
            try {
                e = new XMLHttpRequest
            } catch (t) {
                try {
                    e = new ActiveXObject("Msxml2.XMLHTTP")
                } catch (t) {
                    try {
                        e = new ActiveXObject("Microsoft.XMLHTTP")
                    } catch (e) {
                        this.onError()
                    }
                }
            }
            var s = this;
            e.onreadystatechange = function() {
                4 == s.xhr.readyState && (s.id ? s.getElementContent() : s.loadHTML())
            };
            var i = this.src;
            this.xhr = e, hs.forceAjaxReload && (i = i.replace(/$/, (/\?/.test(i) ? "&" : "?") + "dummy=" + (new Date).getTime())), e.open("GET", i, !0), e.setRequestHeader("X-Requested-With", "XMLHttpRequest"), e.setRequestHeader("Content-Type", "application/x-www-form-urlencoded"), e.send(null)
        },
        getElementContent: function() {
            hs.init();
            var e = window.opera || hs.ie6SSL ? {
                src: "about:blank"
            } : null;
            this.iframe = hs.createElement("iframe", e, {
                position: "absolute",
                top: "-9999px"
            }, hs.container), this.loadHTML()
        },
        loadHTML: function() {
            var e, t = this.cachedGet || this.xhr.responseText;
            if (this.pre && (hs.cachedGets[this.src] = t), !hs.ie || hs.uaVersion >= 5.5)
                if (t = t.replace(new RegExp("<link[^>]*>", "gi"), "").replace(new RegExp("<script[^>]*>.*?<\/script>", "gi"), ""), this.iframe) {
                    var s = this.iframe.contentDocument;
                    if (!s && this.iframe.contentWindow && (s = this.iframe.contentWindow.document), !s) {
                        var i = this;
                        return void setTimeout(function() {
                            i.loadHTML()
                        }, 25)
                    }
                    s.open(), s.write(t), s.close();
                    try {
                        t = s.getElementById(this.id).innerHTML
                    } catch (e) {
                        try {
                            t = this.iframe.document.getElementById(this.id).innerHTML
                        } catch (e) {}
                    }
                    hs.discardElement(this.iframe)
                } else(e = /(<body[^>]*>|<\/body>)/gi).test(t) && (t = t.split(e)[hs.ie ? 1 : 2]);
            for (var h in hs.getElementByClass(this.content, "DIV", "highslide-body").innerHTML = t, this.onLoad(), this) this[h] = null
        }
    }, hs.Slideshow = function(e, t) {
        for (var s in !1 !== hs.dynamicallyUpdateAnchors && hs.updateAnchors(), this.expKey = e, t) this[s] = t[s];
        this.useControls && this.getControls(), this.thumbstrip && (this.thumbstrip = hs.Thumbstrip(this))
    }, hs.Slideshow.prototype = {
        getControls: function() {
            this.controls = hs.createElement("div", {
                innerHTML: hs.replaceLang(hs.skin.controls)
            }, null, hs.container);
            var e = ["play", "pause", "previous", "next", "move", "full-expand", "close"];
            this.btn = {};
            for (var t = 0; t < e.length; t++) this.btn[e[t]] = hs.getElementByClass(this.controls, "li", "highslide-" + e[t]), this.enable(e[t]);
            this.btn.pause.style.display = "none"
        },
        checkFirstAndLast: function() {
            if (!this.repeat && this.controls) {
                var e = hs.expanders[this.expKey],
                    t = e.getAnchorIndex(),
                    s = /disabled$/;
                0 == t ? this.disable("previous") : s.test(this.btn.previous.getElementsByTagName("a")[0].className) && this.enable("previous"), t + 1 == hs.anchors.groups[e.slideshowGroup || "none"].length ? (this.disable("next"), this.disable("play")) : s.test(this.btn.next.getElementsByTagName("a")[0].className) && (this.enable("next"), this.enable("play"))
            }
        },
        enable: function(e) {
            if (this.btn) {
                var t = this,
                    s = this.btn[e].getElementsByTagName("a")[0],
                    i = /disabled$/;
                s.onclick = function() {
                    return t[e](), !1
                }, i.test(s.className) && (s.className = s.className.replace(i, ""))
            }
        },
        disable: function(e) {
            if (this.btn) {
                var t = this.btn[e].getElementsByTagName("a")[0];
                t.onclick = function() {
                    return !1
                }, /disabled$/.test(t.className) || (t.className += " disabled")
            }
        },
        hitSpace: function() {
            this.autoplay ? this.pause() : this.play()
        },
        play: function(e) {
            this.btn && (this.btn.play.style.display = "none", this.btn.pause.style.display = ""), this.autoplay = !0, e || hs.next(this.expKey)
        },
        pause: function() {
            this.btn && (this.btn.pause.style.display = "none", this.btn.play.style.display = ""), clearTimeout(this.autoplay), this.autoplay = null
        },
        previous: function() {
            this.pause(), hs.previous(this.btn.previous)
        },
        next: function() {
            this.pause(), hs.next(this.btn.next)
        },
        move: function() {},
        "full-expand": function() {
            hs.getExpander().doFullExpand()
        },
        close: function() {
            hs.close(this.btn.close)
        }
    }, hs.Thumbstrip = function(e) {
        function t(e) {
            s(void 0, Math.round(e * d[l ? "offsetWidth" : "offsetHeight"] * .7))
        }

        function s(t, s) {
            if (void 0 === t)
                for (var i = 0; i < h.length; i++)
                    if (h[i] == hs.expanders[e.expKey].a) {
                        t = i;
                        break
                    }
            if (void 0 !== t) {
                var n = d.getElementsByTagName("a"),
                    o = n[t],
                    a = o.parentNode,
                    r = "offset" + (l ? "Left" : "Top"),
                    p = "offset" + (l ? "Width" : "Height"),
                    y = c.parentNode.parentNode[p],
                    v = y - m[p],
                    x = parseInt(m.style[l ? "left" : "top"]) || 0,
                    w = x;
                if (void 0 !== s) v > 0 && (v = 0), (w = x - s) > 0 && (w = 0), w < v && (w = v);
                else {
                    for (i = 0; i < n.length; i++) n[i].className = "";
                    o.className = "highslide-active-anchor";
                    var b = t > 0 ? n[t - 1].parentNode[r] : a[r],
                        z = a[r] + a[p] + (n[t + 1] ? n[t + 1].parentNode[p] : 0);
                    z > y - x ? w = y - z : b < -x && (w = -b)
                }
                var E = a[r] + (a[p] - f[p]) / 2 + w;
                hs.animate(m, l ? {
                    left: w
                } : {
                    top: w
                }, null, "easeOutQuad"), hs.animate(f, l ? {
                    left: E
                } : {
                    top: E
                }, null, "easeOutQuad"), u.style.display = w < 0 ? "block" : "none", g.style.display = w > v ? "block" : "none"
            }
        }
        for (var i, h = hs.anchors.groups[hs.expanders[e.expKey].slideshowGroup || "none"], n = e.thumbstrip, o = n.mode || "horizontal", a = "float" == o, r = a ? ["div", "ul", "li", "span"] : ["table", "tbody", "tr", "td"], l = "horizontal" == o, d = hs.createElement("div", {
                className: "highslide-thumbstrip highslide-thumbstrip-" + o,
                innerHTML: '<div class="highslide-thumbstrip-inner"><' + r[0] + "><" + r[1] + "></" + r[1] + "></" + r[0] + '></div><div class="highslide-scroll-up"><div></div></div><div class="highslide-scroll-down"><div></div></div><div class="highslide-marker"><div></div></div>'
            }, {
                display: "none"
            }, hs.container), p = d.childNodes, c = p[0], u = p[1], g = p[2], f = p[3], m = c.firstChild, y = d.getElementsByTagName(r[1])[0], v = 0; v < h.length; v++) 0 != v && l || (i = hs.createElement(r[2], null, null, y)),
            function() {
                var e = h[v],
                    t = hs.createElement(r[3], null, null, i);
                hs.createElement("a", {
                    href: e.href,
                    onclick: function() {
                        return hs.getExpander(this).focus(), hs.transit(e)
                    },
                    innerHTML: hs.stripItemFormatter ? hs.stripItemFormatter(e) : e.innerHTML
                }, null, t)
            }();
        return a || (u.onclick = function() {
            t(-1)
        }, g.onclick = function() {
            t(1)
        }, hs.addEventListener(y, void 0 !== document.onmousewheel ? "mousewheel" : "DOMMouseScroll", function(e) {
            var s = 0;
            (e = e || window.event).wheelDelta ? (s = e.wheelDelta / 120, hs.opera && (s = -s)) : e.detail && (s = -e.detail / 3), s && t(.2 * -s), e.preventDefault && e.preventDefault(), e.returnValue = !1
        })), {
            add: function(e) {
                hs.extend(n || {}, {
                    overlayId: d,
                    hsId: "thumbstrip",
                    className: "highslide-thumbstrip-" + o + "-overlay " + (n.className || "")
                }), hs.ieLt7 && (n.fade = 0), e.createOverlay(n), hs.setStyles(d.parentNode, {
                    overflow: "hidden"
                })
            },
            selectThumb: s
        }
    }, hs.langDefaults = hs.lang;
    var HsExpander = hs.Expander;
    hs.ie && window == window.top && function() {
        try {
            document.documentElement.doScroll("left")
        } catch (e) {
            return void setTimeout(arguments.callee, 50)
        }
        hs.ready()
    }(), hs.addEventListener(document, "DOMContentLoaded", hs.ready), hs.addEventListener(window, "load", hs.ready), hs.addEventListener(document, "ready", function() {
        if (hs.expandCursor || hs.dimmingOpacity) {
            var e = hs.createElement("style", {
                type: "text/css"
            }, null, document.getElementsByTagName("HEAD")[0]);

            function t(t, s) {
                if (hs.ie) {
                    var i = document.styleSheets[document.styleSheets.length - 1];
                    "object" == typeof i.addRule && i.addRule(t, s)
                } else e.appendChild(document.createTextNode(t + " {" + s + "}"))
            }

            function s(e) {
                return "expression( ( ( ignoreMe = document.documentElement." + e + " ? document.documentElement." + e + " : document.body." + e + " ) ) + 'px' );"
            }
            hs.expandCursor && t(".highslide img", "cursor: url(" + hs.graphicsDir + hs.expandCursor + "), pointer !important;"), t(".highslide-viewport-size", hs.ie && (hs.uaVersion < 7 || "BackCompat" == document.compatMode) ? "position: absolute; left:" + s("scrollLeft") + "top:" + s("scrollTop") + "width:" + s("clientWidth") + "height:" + s("clientHeight") : "position: fixed; width: 100%; height: 100%; left: 0; top: 0")
        }
    }), hs.addEventListener(window, "resize", function() {
        if (hs.getPageSize(), hs.viewport)
            for (var e = 0; e < hs.viewport.childNodes.length; e++) {
                var t = hs.viewport.childNodes[e],
                    s = hs.getExpander(t);
                s.positionOverlay(t), "thumbstrip" == t.hsId && s.slideshow.thumbstrip.selectThumb()
            }
    }), hs.addEventListener(document, "mousemove", function(e) {
        hs.mouse = {
            x: e.clientX,
            y: e.clientY
        }
    }), hs.addEventListener(document, "mousedown", hs.mouseClickHandler), hs.addEventListener(document, "mouseup", hs.mouseClickHandler), hs.addEventListener(document, "ready", hs.setClickEvents), hs.addEventListener(window, "load", hs.preloadImages), hs.addEventListener(window, "load", hs.preloadAjax), hs.graphicsDir = "/highslide/", hs.outlineType = "rounded-white", hs.wrapperClassName = "draggable-header", hs.captionEval = "this.a.title", hs.showCredits = !1, hs.marginTop = 20, hs.marginRight = 20, hs.marginBottom = 20, hs.marginLeft = 20, hs.zIndexCounter = 1055
}
