import * as matchValues from './match_values'
import * as mfaModal from './mfa_modal'

$(function () {
  $(document).ready(function () {
    // `Edit Account` & `Edit Password` pages: on form submission, hide alert container
    $('#user_form_submit, #password_form_submit').on('click', function () {
      $('#alert_container').hide()
    })
    matchValues.conditionalBlocking('#user_phone_number', '#phone_number_confirmation', '#user_form_submit', 'Phone number fields must match')
    mfaModal.mfaModalForm()
  })
})
