export const pageSize = 6
export function showPage (page, measurementType) {
  measurementType.children('.line-content').hide()
  measurementType.children('.line-content').each(function (n) {
    if (n >= pageSize * (page - 1) && n < pageSize * page) {
      const img = $(this).find('img')
      img.attr('src', img.attr('data-src-placeholder'))
      $(this).show()
    }
  })
}

export function showModal () {
  $('.thumbnail img').click(function () {
    const src = $(this).attr('data-modal-src')
    $('#attachment-modal .modal-body').html('<img src="' + src + '">')
    $('#attachment-modal').modal()
  })
}
