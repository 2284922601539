export function conditionalBlocking (sourceInputId, currentInputId, actionButtonId, alertTooltipMessage) {
  let sourceValue = $(sourceInputId).val()

  $(currentInputId).after("<div id='alert_tooltip' style='display: none; color: #b94a48;'>" + alertTooltipMessage + '</div>')

  if ((sourceValue !== $(currentInputId).val())) {
    $(actionButtonId).attr('disabled', true)
  }

  $(sourceInputId).on('keyup', function () {
    toggleElements(sourceInputId, currentInputId, actionButtonId)
  })

  $(currentInputId).on('keyup', function () {
    toggleElements(sourceInputId, currentInputId, actionButtonId)
  })
}

function toggleElements (sourceInputId, currentInputId, actionButtonId) {
  if ($(sourceInputId).val() === $(currentInputId).val()) {
    $(actionButtonId).attr('disabled', false)
    $('#alert_tooltip').hide()
  } else {
    $(actionButtonId).attr('disabled', true)
    $('#alert_tooltip').show()
  }
}
