import 'datatables.net'
import 'datatables.net-bs'
import 'datatables.net-buttons'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import './datatable_moment'
import moment from 'moment'
import * as linkSetup from 'datatables/link_setup'

// for more in depth on sorting functions
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort#description
jQuery.fn.dataTableExt.oSort['date-empty-on-bottom-asc'] = function (x, y) {
  let retVal
  x = moment(new Date(parseInt(x)))
  y = moment(new Date(parseInt(y)))

  if (x.isSame(y)) retVal = 0
  else if (!x.isValid() && !y.isValid()) retVal = 0
  else if (!x.isValid()) retVal = 1
  else if (!y.isValid()) retVal = -1
  else if (x.isAfter(y)) retVal = 1
  else retVal = -1 // <- this was missing in version 1

  return retVal
}

jQuery.fn.dataTableExt.oSort['date-empty-on-bottom-desc'] = function (x, y) {
  let retVal
  x = moment(new Date(parseInt(x)))
  y = moment(new Date(parseInt(y)))

  if (x.isSame(y)) retVal = 0
  else if (!x.isValid() && !y.isValid()) retVal = 0
  else if (!x.isValid()) retVal = 1
  else if (!y.isValid()) retVal = -1
  else if (x.isAfter(y)) retVal = -1
  else retVal = 1 // <- this was missing in version 1

  return retVal
}

export function initializeUnlinkedTable ($table) {
  let sortColumn = 0
  if ($table.data('sort_column') !== undefined) {
    sortColumn = $table.data('sort_column')
  }
  let sortDirection = 'asc'
  if ($table.data('sort_direction') === 'desc') {
    sortDirection = 'desc'
  }

  let config = {
    'pagingType': 'full_numbers',
    'dom': 'frtilp<"clear">',
    'aaSorting': [[sortColumn, sortDirection]],
    'oLanguage': {
      'sSearch': 'Filter Results: '
    },
    'bPaginate': false,
    'bInfo': false
  }

  let dateCols = $table.data('date-columns')
  let dateTimeCols = $table.data('datetime-columns')

  if (typeof dateCols !== 'undefined') {
    config.columnDefs = [
      {
        targets: dateCols.toString().split(',').map(Number),
        render: $.fn.dataTable.render.moment(moment.ISO_8601, 'MM-DD-YYYY'),
        type: 'date-empty-on-bottom'
      }
    ]
  }

  if (typeof dateTimeCols !== 'undefined') {
    config.columnDefs = [
      {
        targets: dateTimeCols.toString().split(',').map(Number),
        render: $.fn.dataTable.render.moment(moment.ISO_8601, 'MM-DD-YYYY h:mm A')
      }
    ]
  }

  $table.on('processing.dt', showTableOnCompletion).DataTable(config)
  $table.closest('.dataTables_wrapper').addClass('legacy-datatable-wrapper')

  return $table.DataTable()
}

export function initializeClickableTable ($table) {
  let config = {
    'columnDefs': [
      { 'orderable': false, 'targets': $table.data('disabled_sorting') }
    ],
    'pagingType': 'full_numbers',
    'dom': 'frtilp<"clear">',
    'aaSorting': [[1, 'asc']],
    'oLanguage': {
      'sSearch': 'Filter Results: '
    },
    'buttons': [],
    stateSave: true,
    'fnDrawCallback': function () {
      $table.find('tbody tr.clickable').click(function () {
        const id = $(this).attr('id')
        document.location.href = $($(this).closest('table')).data('row-link').replace('[:id]', id)
      })
    },
    initComplete: handleHyperlinkColumn
  }

  if ($table.data('sort_column') !== undefined) {
    config.aaSorting[0][0] = $table.data('sort_column')
  }
  if ($table.data('sort_format') !== undefined) {
    config.aaSorting[0][1] = $table.data('sort_format')
  }
  if ($table.data('display_length') !== undefined) {
    config.iDisplayLength = $table.data('display_length')
  }
  if ($table.data('length_menu') !== undefined) {
    config.aLengthMenu = $table.data('length_menu')
  }
  if ($table.data('colvis') !== undefined) {
    config.buttons = [
      ...config.buttons,
      {extend: 'colvis', text: 'Column Visibility'}
    ]
    config.dom = `B${config.dom}`
  }

  $table.on('processing.dt', showTableOnCompletion).DataTable(config)
  $table.closest('.dataTables_wrapper').addClass('legacy-datatable-wrapper')

  return $table.DataTable()
}

export function initializeClickableCSVTable ($table) {
  let sortColumn = 1
  let searchableColumns = 1

  if ($table.data('sort_column') !== undefined) {
    sortColumn = $table.data('sort_column')
  }
  if ($table.data('searchable_columns') !== undefined) {
    searchableColumns = $table.data('searchable_columns')
  }

  $table.on('processing.dt', showTableOnCompletion).DataTable({
    'bPaginate': false,
    'dom': 'Bfrti<"clear">',
    'aaSorting': [[sortColumn, 'asc']],
    'oLanguage': {
      'sSearch': 'Filter Results: '
    },
    'buttons': [{extend: 'csvHtml5',
      text: 'Download CSV',
      exportOptions: {columns: ':visible'}},
    {extend: 'colvis', text: 'Column Visibility'}],
    initComplete: function () {
      $('.dt-buttons').append('<a class="dt-button sort-reset" href="#"><span>Reset sorting</span></a>')
      this.api().columns(searchableColumns).every(function () {
        const column = this
        const select = $('<select><option value=""></option></select>')
          .appendTo($(column.footer()).empty())
          .on('change', function () {
            const val = $.fn.dataTable.util.escapeRegex($(this).val())
            column.search(val ? '^' + val + '$' : '', true, false).draw()
          })
        column.data().unique().sort().each(function (d, j) {
          select.append('<option value="' + d + '">' + d + '</option>')
        })
      })
    },
    stateSave: true,
    stateSaveCallback: function (settings, data) {
      localStorage.setItem('DataTables_' + settings.sInstance, JSON.stringify(data))
    },
    stateLoadCallback: function (settings) {
      return JSON.parse(localStorage.getItem('DataTables_' + settings.sInstance))
    },
    drawCallback: function () {
      $table.find('tbody tr.clickable').click(function () {
        const id = $(this).attr('id')
        document.location.href = $($(this).closest('table')).data('row-link').replace('[:id]', id)
      })
    },
    rowCallback: function (row, data, displayIndex) {
      row.className = 'clickable'
      return row
    }
  })

  $table.closest('.dataTables_wrapper').addClass('legacy-datatable-wrapper')

  $('.sort-reset').click(function () {
    $table.DataTable().state.clear()
    window.location.reload()
  })

  return $table.DataTable()
}

export function initializeAjaxClickableTable ($table) {
  let config = {
    'columnDefs': [
      { 'orderable': false, 'targets': $table.data('disabled_sorting') }
    ],
    'pagingType': 'full_numbers',
    'processing': true,
    'serverSide': true,
    'ajax': {
      'url': $table.data('source'),
      'type': 'POST'
    },
    'dom': 'frtilp',
    'aaSorting': [[1, 'asc']],
    'language': {
      'search': 'Filter Results: '
    },
    'sortMulti': false,
    stateSave: true,
    stateSaveCallback: function (settings, data) {
      localStorage.setItem('DataTables_' + settings.sInstance, JSON.stringify(data))
    },
    stateLoadCallback: function (settings) {
      return JSON.parse(localStorage.getItem('DataTables_' + settings.sInstance))
    },
    'drawCallback': function () {
      $table.find('tbody tr.clickable').click(function () {
        const id = $(this).attr('id')
        document.location.href = $($(this).closest('table')).data('row-link').replace('[:id]', id)
      })
    },
    'rowCallback': function (row, data, displayIndex) {
      let clickable = ''
      if ($table.data('row-link') !== undefined) {
        clickable = 'clickable'
      }
      row.className = clickable
      return row
    },
    initComplete: handleHyperlinkColumn
  }

  if ($table.data('sort_column') !== undefined) {
    config.aaSorting[0][0] = $table.data('sort_column')
  }
  if ($table.data('display_length') !== undefined) {
    config.iDisplayLength = $table.data('display_length')
  }
  if ($table.data('length_menu') !== undefined) {
    config.aLengthMenu = $table.data('length_menu')
  }
  if ($table.data('ajax_reload') === true) {
    config.processing = false
  }

  $table.on('processing.dt', showTableOnCompletion).DataTable(config)
  $table.closest('.dataTables_wrapper').addClass('legacy-datatable-wrapper')

  if ($table.data('ajax_reload') === true) {
    setInterval(function () {
      $table.DataTable().ajax.reload(null, false) // user paging is not reset on reload
    }, 3000)
  }

  return $table.DataTable()
}

export function searchableColumns ($table) {
  const datatable = $table.DataTable()

  // Search inputs for each column footer row in episodes table
  $table.find('tfoot th').each(function () {
    $(this).html('<input type="text" />')
  })

  // search filtering for each column
  // Restore state
  const state = datatable.state.loaded()
  if (state) {
    datatable.columns().eq(0).each(function (colIdx) {
      const colSearch = state.columns[colIdx].search
      if (colSearch.search) {
        $('input', datatable.column(colIdx).footer()).val(colSearch.search)
      }
    })
    datatable.draw(false)
  }
  // Apply the search
  datatable.columns().eq(0).each(function (colIdx) {
    $('input', datatable.column(colIdx).footer()).on('keyup change', function () {
      datatable.column(colIdx).search(this.value).draw()
    })
  })
}

// TODO: move into initDatatable

export function showTableOnCompletion (e, settings, processing) {
  if (processing) { return }
  $(this).addClass('loading_complete')
  $('.dataTables_wrapper').addClass('loading_complete')
}

export function handleHyperlinkColumn () {
  // prevent hyperlink/modal column in navigation dashboard from clickable row propagation
  linkSetup.handleRemoteSubmissionLink()
  if (!$('.datatable').hasClass('trigger-column-js')) {
    $('.datatable tr td a').each(function () {
      $(this).parent().click(function (e) {
        e.stopPropagation()
      })
    })
  }
}
