// close all highslide modals when element with given class is clicked
export function setCloseHighslideModalsListener () {
  $('.close-hs-modal').on('click', function () {
    window.hs.expanders.filter((obj) => obj).forEach(function (exp) {
      exp.close()
    })
  })
}

export function adjustStyles () {
  $('body').removeClass('modal-open')
  $('.modal').css('position', 'absolute')
  $('.modal').css('overflow', 'inherit')
}
