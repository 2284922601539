export function displayCalendar () {
  $('#calendarwrap').show() // keep this after the AJAX call or calendar may not show
  $('.scheduled-for-picker').slideDown()
  $('#do_not_schedule').prop('checked', false)
}

export function hideScheduleDate () {
  $('.task_scheduled_for').hide()
  $('#task_scheduled_for').val('')
  $('.recurring_task_options').hide()
  $('#task_frequency').val('Once')
  $('.recurring_task_options').find('input:checkbox').prop('checked', false)
  $('#task_until').val('')
}

export function setStartDate () {
  const startDate = $('.start-date')
  if (startDate.length > 0) {
    const scheduledFor = $("input[name='task[scheduled_for]']")
    startDate.html(scheduledFor.val())
    scheduledFor.blur(function () {
      startDate.html($(this).val().split(' ')[0])
    })
  }
}
