import * as select2Setup from 'select2_setup/select2_setup'

export function stripedPrompts () {
  $('.form-prompt:even').removeClass('striped-form-prompt')
  $('.form-prompt:odd').addClass('striped-form-prompt')
}

export function removePrompt () {
  $('.remove-prompt').off('click')
  $('.remove-prompt').click(function () {
    document.getElementById($(this).data('id').toString()).remove()
    stripedPrompts()
  })
}

export function duplicatePrompt () {
  $('.duplicate-prompt').off('click')
  $('.duplicate-prompt').click(function () {
    const list = $('#prompt-container')
    const prompt = document.getElementById($(this).data('id').toString())
    const clone = prompt.cloneNode(true)
    const newId = `prompt-${new Date().getTime()}`

    // update old prompt id for new one
    clone.id = newId
    clone.dataset.select2Id = newId
    $('#prompts__id', clone).val(newId)
    $('.remove-prompt', clone).attr('data-id', newId)
    $('.add-follow-up', clone).parent().attr('href', `/add_prompt?id=${newId}`)
    $('.duplicate-prompt', clone).attr('data-id', newId)

    // get select2 values programatically
    const typeData = $('.basic-select2', clone).select2('data')
    const choicesData = $('.basic-select2-with-other', clone).select2('data')

    // remove select2 elements manually, these will be reacreated later
    $('.select2.select2-container', clone).remove()

    // update old prompt id for new one
    $('.basic-select2', clone).attr('id', newId)
    $('.basic-select2', clone).attr('data-select2-id', newId)
    $('.basic-select2-with-other', clone).attr('id', `${newId}_choices`)
    $('.basic-select2-with-other', clone).attr('data-select2-id', `${newId}_choices`)

    // attach clone to DOM
    list.append(clone)

    // setup select2, here select2 elements get recreated
    select2Setup.multiSelect()
    select2Setup.sortableSelect()

    // copy type value
    $(`#${newId}.basic-select2`).val(typeData[0].id)
    $(`#${newId}.basic-select2`).trigger('change')

    // copy tags
    choicesData.forEach((choice) => {
      if (!$(`option[value='${choice.id}']`, clone)) {
        let option = $(`<option value=${choice.id}>${choice.id}</option>`)
        $('.basic-select2-with-other', clone).append(option)
      }
    })

    $('.basic-select2-with-other', clone).val(choicesData.map((c) => c.id))
    $('.basic-select2-with-other', clone).trigger('change')

    // set latest sequence
    const sequences = Array.from(document.querySelectorAll('#sequence'))
    const nextSequence = Math.max(...sequences.map((seq) => parseInt(seq.value))) + 1
    $('#sequence', clone).val(nextSequence)
    clone.scrollIntoView()

    // setup event listeners again
    duplicatePrompt()
    removePrompt()
  })
}
