import * as Highcharts from 'highcharts'
import 'highcharts-more'
import * as modals from './modals'
import * as surveys from './surveys'
import * as media from './media'
import * as testing from './testing'
window.Highcharts = Highcharts
window.patientSummaryModal = modals

// set the theme    ====================
Highcharts.setOptions({
  chart: {
    backgroundColor: 'rgba(255, 255, 255, 0)',
    plotBackgroundColor: 'rgba(255, 255, 255, .9)',
    plotShadow: true,
    plotBorderWidth: 1,
    zoomType: 'x',
    resetZoomButton: {
      position: {
        x: -10,
        y: -40
      }
    },
    marginRight: 20
  },
  credits: {
    enabled: false
  },
  exporting: {
    enabled: false
  },
  xAxis: {
    type: 'datetime',
    dateTimeLabelFormats: {
      year: '%b-%e',
      week: '%b-%e',
      month: '%b-%e',
      day: '%b-%e',
      hour: '%b-%e',
      minute: '%b-%e',
      second: '%b-%e',
      millisecond: '%b-%e'
    },
    minTickInterval: 1,
    minRange: 1209600000, // 2 weeks in milliseconds,
    startOnTick: true,
    endOnTick: true
  },
  legend: {
    enabled: false
  },
  yAxis: {
    lineWidth: 1,
    minorTickWidth: 1,
    minorTickLength: 3,
    minorGridLineColor: '#DDDDDD',
    minorTickInterval: 'auto'
  },
  plotOptions: {
    series: {
      animation: false,
      marker: {
        radius: 4,
        lineColor: null,
        enabled: true,
        fillColor: 'white',
        lineWidth: 2
      }
    }
  }
})

$(function () {
  // Pagination
  $('.measurement-type').each(function () {
    let pageCount = Math.ceil($(this).children('.line-content').size() / media.pageSize)
    for (let i = 0; i < pageCount; i++) {
      if (i === 0) {
        $(this).find('.pagination').append('<li class="page-item active"><a class="page-link" href="#">' + (i + 1) + '</a></li>')
      } else {
        $(this).find('.pagination').append('<li class="page-item"><a class="page-link" href="#">' + (i + 1) + '</a></li>')
      }
    }
    media.showPage(1, $(this))
  })

  $('.pagination li a').click(function (event) {
    event.preventDefault()
    $(this).closest('.pagination').children('li').removeClass('active')
    $(this).parent('li').addClass('active')
    media.showPage(parseInt($(this).text()), $(this).closest('.measurement-type'))
  })

  media.showModal()

  // toggle carets for surveys
  surveys.toggleCarets()
  surveys.stopLinkPropagation()

  modals.setCloseHighslideModalsListener()

  // PDF Summary: DatePickers for Testing page
  testing.formatTestingDatePickers()

  $('a[href="#messages-tab"]').on('shown.bs.tab', function (e) {
    var messageBody = document.querySelector('.chat-history')
    messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight
  })
})
