import moment from 'moment'

$.fn.dataTable.render.moment = function (from, to, locale) {
  if (arguments.length === 1) {
    locale = 'en'
    to = from
    from = 'YYYY-MM-DD'
  } else if (arguments.length === 2) {
    locale = 'en'
  }

  return function (d, type, row) {
    if (!d) return null
    return moment(d, from, locale, true).format(type === 'sort' || type === 'type' ? 'x' : to)
  }
}
