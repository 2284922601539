export function autoAdjustSequence () {
  $('#program_fields').on('cocoon:after-insert', function (e, insertedItem) {
    let maxSequence = getMaxSequenceValue('.nested-fields .sequence.episode_info')
    insertedItem.find('.sequence').val(maxSequence + 1)
    autoAdjustSequenceBasedOnCategory()
    hideRequiredOptionBasedOnStandardFieldSelection()
  })

  $('#program_fields').on('cocoon:after-remove', function (e, removedItem) {
    removedItem.find('.sequence').removeClass('sequence')

    let items = $('.nested-fields .sequence.episode_info')
    let count = 0
    for (let item of items) { count++; item.value = count }

    items = $('.nested-fields .sequence.medical_info')
    count = 0
    for (let item of items) { count++; item.value = count }
  })
}

export function autoAdjustSequenceBasedOnCategory () {
  $('.category').on('change', function () {
    let selected = $(this).val()
    let filter = '.nested-fields .sequence.' + selected
    let maxSequence = getMaxSequenceValue(filter)

    let parent = $(this).closest('.nested-fields')
    let sequenceInput = parent.find('.sequence')
    sequenceInput.val(maxSequence + 1)

    let categoryClass = sequenceInput.attr('class').split(' ').pop()
    sequenceInput.removeClass(categoryClass) // e.g.: `medical_info` removed
    sequenceInput.addClass(selected) // then `episode_info` is added, and viceversa
  })
}

export function hideRequiredOptionBasedOnStandardFieldSelection () {
  $('.database-field-type').on('change', function () {
    let parent = $(this).closest('.nested-fields')
    let requiredCheckBox = parent.find('div.checkbox input.boolean.optional')[0]
    let selected = $(this).val()
    if (selected) {
      requiredCheckBox.disabled = true
    } else {
      requiredCheckBox.disabled = false
    }
  })
}

function getMaxSequenceValue (filter) {
  let maxSequence = 0

  $(filter).each(function (index, element) {
    let value = parseInt($(element).val())
    if (value > maxSequence) { maxSequence = value }
  })

  return maxSequence
}
