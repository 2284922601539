export function selectOther () {
  $('.select_other').change(function () {
    const sel = $(this)
    const isMultiSelect = sel.hasClass('multi-other')
    let otherSelected = false
    if (isMultiSelect) {
      $.each(sel.val(), function () {
        if (this.toLowerCase() === 'other') {
          otherSelected = true
        }
      })
      sel.find('option:selected').each(function () {
        if ($(this).text().toLowerCase() === 'other') {
          otherSelected = true
        }
      })
    } else {
      if (sel.find('option:selected').text().toLowerCase() === 'other' ||
          sel.val().toLowerCase() === 'other') {
        otherSelected = true
      }
    }

    if (otherSelected) {
      $.prompt('<label>Please enter a value for "other":<br/><input type="text" id="other-val" name="other-val" value=""></label><br />', {
        focus: '#other-val',
        buttons: { Ok: true, Cancel: false },
        classes: {
          title: 'lead',
          button: 'btn btn-primary',
          defaultButton: 'btn btn-primary'
        },
        submit: function (e, v, m, f) {
          sel.focus()
          if (!v) {
            return true
          }
          if (isMultiSelect) {
            $("option[value='Other']", sel).prop('selected', false)
            $("option[value='OTHER']", sel).prop('selected', false)
            $("option[value='']", sel).remove()
          }
          if (f['other-val'] !== '') {
            sel.append('<option value="' +
                           f['other-val'] +
                           '" selected="selected">' +
                           f['other-val'] +
                           '</option>')
          }
          return true
        }
      })
    }
  })
}
