export function changeMedicationList () {
  $('.category').change(function () {
    const category = this
    const catIndex = $($('.category')).index(this)
    const classList = $('.prescription_class')
    const prescriptionClass = $('.prescription_class').eq(catIndex)[0]
    const selCategory = category.options[category.selectedIndex].value
    while (prescriptionClass.options.length) {
      prescriptionClass.remove(0)
    }
    const meds = classList.data(selCategory.toLowerCase())
    if (meds) {
      for (let i = 0; i < meds.length; i++) {
        const med = new Option(meds.sort()[i])
        prescriptionClass.options.add(med)
      }
    }
  })
}
