$(function () {
  $(document).ready(function () {
    $('#spanish_checkbox .checkbox input').on('change', function (e) {
      $('.alert_es_input').toggleClass('hidden')
      $('.alert_en_input').toggleClass('col-xs-12 col-xs-6')
    })
    $('#scheduling_view_checkbox .checkbox input').on('change', function (e) {
      $('#scheduling_view_input').toggleClass('hidden')
    })
  })
})
