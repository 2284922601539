export default class {
  /**
   * Set devMode to true for testing settings (10 second keepalive, 20 second warning,
   * 1 minute timeout).  Also update config.timeout_in in config/initializers/devise.rb
   * to 1.minute and restart the server.
   * @param boolean devMode=false
   */
  constructor (devMode = false) {
    this.initSettings(devMode)
    this.lastUpdate = 0
    setInterval(function () {
      if ($.now() - this.lastUpdate < this.keepalivePeriod) {
        this.Timeouts.keepalive(true)
      } else {
        this.Timeouts.queryTimeLeft(true)
      }
    }, this.keepalivePeriod)
  }

  initSettings (devMode) {
    if (devMode) {
      this.keepalivePeriod = 10 * 1000 // (in milliseconds)
      this.warnPeriod = 20 // (in seconds)
      this.logTimeLeft = true
    } else {
      if (typeof window.keepaliveOverride === 'undefined') {
        this.keepalivePeriod = 1 * 60 * 1000 // (in milliseconds) check for activity every 1 minute and issue keepalive if there has been any
      } else {
        this.keepalivePeriod = window.keepaliveOverride
      }
      this.warnPeriod = 20 * 60 // (in seconds) warn 20 minutes before timeout
      this.logTimeLeft = false
    }
    this.timeoutWarningDisplayed = false
  }

  timeoutOverlay () {
    this.timeoutWarningDisplayed = true
    $('#timeout-overlay').fadeIn(500)
    return true
  }

  dismissTimeoutOverlay () {
    this.timeoutWarningDisplayed = false
    $('#timeout-overlay').fadeOut(500)
    return true
  }

  redirectForExpire () {
    this.promptforsave = 0
    window.location.href = '/users/sign_in?expired'
  }

  processTimeLeft (timeLeft, doWarn) {
    if (this.logTimeLeft) {
      console.log(timeLeft)
    }
    if (timeLeft <= 0) {
      this.redirectForExpire()
    } else if (doWarn && (timeLeft <= this.warnPeriod)) {
      this.timeoutOverlay()
    }
  }

  keepalive (doWarn) {
    const self = this
    $.getJSON('/keepalive', function (timeLeft) {
      self.processTimeLeft(timeLeft, doWarn)
    }).fail(function (jqXHR) {
      if (jqXHR.status === 401) {
        self.redirectForExpire()
      }
    })
  }

  queryTimeLeft () {
    const self = this
    $.getJSON('/timeout_time_left', function (timeLeft) {
      self.processTimeLeft(timeLeft, true)
    }).fail(function (jqXHR) {
      if (jqXHR.status === 401) {
        self.redirectForExpire()
      }
    })
  }

  resetLastUpdate () {
    this.lastUpdate = $.now()
    if (this.timeoutWarningDisplayed) {
      this.keepalive(false) // immediately keepalive to try to keep session alive in final seconds
      this.dismissTimeoutOverlay()
    }
  }
}
